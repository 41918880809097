<template>
  <mdb-container fluid>
    <!-- Section: Grid -->
    <section style="height: calc(100vh - 62px);">
      <ejs-grid
        ref="grid"
        locale="de"
        height="100%"
        :dataSource="dataSource"
        :dataBound="dataBound"
        :toolbar="toolbar"
        :toolbarClick="clickToolbar"
        :allowSorting="true"
        :sortSettings="sortSettings"
        :rowSelected="selectRow"
      >
        <e-columns>
          <e-column
            field="id"
            headerText="ID"
            textAlign="Left"
          ></e-column>
          <e-column
            field="title"
            headerText="Anrede"
            textAlign="Left"
          ></e-column>
          <e-column
            field="first_name"
            headerText="Vorname"
            textAlign="Left"
            :customAttributes="ColumnAttributes"
          ></e-column>
          <e-column
            field="last_name"
            headerText="Nachname"
            textAlign="Left"
            :customAttributes="ColumnAttributes"
          ></e-column>
          <e-column
            field="heading"
            headerText="Überschrift"
            textAlign="Left"
          ></e-column>
          <e-column
            field="url_1"
            headerText="Profiladresse A"
            textAlign="Left"
          ></e-column>
          <e-column
            field="url_2"
            headerText="Profiladresse B"
            textAlign="Left"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </section>
    <!-- /Section: Grid -->
    <!-- Modal: Cook -->
    <mdb-modal
      side
      position="right"
      direction="right"
      full-height
      size="lg"
      :show="showModal"
      @close="closeModal"
    >
      <mdb-modal-header>
        <mdb-modal-title v-if="modalEdit">Koch bearbeiten</mdb-modal-title>
        <mdb-modal-title v-else>Koch hinzufügen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <form id="modal-form" novalidate @submit.prevent="saveModal" @keydown.enter.prevent>
          <mdb-row>
            <mdb-col md="6" class="mb-2">
              <legend><mdb-icon icon="utensils" fas class="light-green-text" /> Koch</legend>
              <mdb-select
                label="Anrede"
                v-model="model.title"
                ref="title"
                validation
              />
              <mdb-input
                label="Vorname"
                type="text"
                v-model="model.first_name"
                required
              />
              <mdb-input
                label="Nachname"
                type="text"
                v-model="model.last_name"
                required
              />
            </mdb-col>
            <mdb-col md="6" class="mb-2">
              <legend><mdb-icon icon="external-link-alt" fas class="light-green-text" /> Profil</legend>
              <mdb-input
                label="Profiladresse A"
                type="text"
                v-model="model.url_1"
              />
              <mdb-input
                label="Profiladresse B"
                type="text"
                v-model="model.url_2"
              />
              <mdb-input
                label="Bild"
                type="text"
                v-model="model.image"
              />
              <mdb-input
                label="Überschrift"
                type="text"
                v-model="model.heading"
              />
              <mdb-input
                label="Text"
                type="textarea"
                v-model="model.description"
                :rows="5"
              />
            </mdb-col>
          </mdb-row>
          <mdb-row>
            <mdb-col md="12" class="mb-2" v-if="modalEdit">
              <legend><mdb-icon icon="angle-double-right" fas class="light-green-text" /> Aktionen</legend>
              <mdb-btn color="danger" @click.native="showDelete = true"><mdb-icon icon="ban" fas class="mr-1"/> Koch löschen</mdb-btn>
            </mdb-col>
          </mdb-row>
        </form>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="light-green" @click.native="closeModal">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="light-green" v-if="modalEdit">Speichern</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="light-green" v-else>Hinzufügen</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Cook -->
    <!-- Modal: Delete -->
    <mdb-modal size="lg" :show="showDelete" @close="showDelete = false" danger>
      <mdb-modal-header>
        <p class="heading"><strong>Koch löschen</strong></p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="ban" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie den gewählten Koch wirklich löschen?</strong>
            </p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-title>Hinweis</mdb-card-title>
                <mdb-card-text>
                  <p>Dieser Vorgang schlägt fehl, falls der Koch bereits einem Kurs zugeordnet wurde.</p>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="danger" @click="showDelete = false">Abbrechen</mdb-btn>
        <mdb-btn form="modal-form" color="danger" @click.native="cancelEntry">Löschen</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Delete -->
  </mdb-container>
</template>

<script>
import Vue from 'vue'
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json'
import * as cagregorian from 'cldr-data/main/de/ca-gregorian.json'
import * as numbers from 'cldr-data/main/de/numbers.json'
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json'
import * as weekData from 'cldr-data/supplemental/weekData.json'
import * as currencies from 'cldr-data/main/de/currencies.json'
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base'
import { GridPlugin, Resize, Toolbar, Sort, Search } from '@syncfusion/ej2-vue-grids'
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data'
import axios from 'axios'
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbIcon,
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbInput,
  mdbSelect,
  mdbCard,
  mdbCardBody,
  mdbCardTitle,
  mdbCardText
} from 'mdbvue'

L10n.load({
  'de': {
    'grid': {
      'EmptyRecord': 'Keine Einträge gefunden',
      'GroupDropArea': 'Spalte hier ablegen um zu gruppieren',
      'UnGroup': 'Klicken Sie hier, um die Gruppierung aufheben',
      'Item': 'Eintrag',
      'Items': 'Einträge',
      'Search': 'Suche',
      'FilterButton': 'Filtern',
      'ClearButton': 'Löschen',
      'SelectAll': 'Alle',
      'Blanks': 'Leer',
      'Add': 'Hinzufügen',
      'Edit': 'Bearbeiten',
      'Delete': 'Löschen',
      'Enter the value': 'Suchbegriff eingeben'
    },
    'pager': {
      'currentPageInfo': '{0} von {1} Seiten',
      'totalItemsInfo': '({0} Einträge)',
      'firstPageTooltip': 'Zur ersten Seite',
      'lastPageTooltip': 'Zur letzten Seite',
      'nextPageTooltip': 'Zur nächsten Seite',
      'previousPageTooltip': 'Zurück zur letzten Seit',
      'nextPagerTooltip': 'Zum nächsten Pager',
      'previousPagerTooltip': 'Zum vorherigen Pager'
    }
  }
})
loadCldr(numberingSystems, cagregorian, numbers, timeZoneNames, weekData, currencies)
setCulture('de')
setCurrencyCode('EUR')

window.axios = axios
axios.defaults.baseURL = 'https://fpk.sempre-diritto.de/api/'

Vue.use(GridPlugin)

export default {
  name: 'Cooks',
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
    mdbSelect,
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText
  },
  data () {
    return {
      model: {
        id: 'new',
        title: [
          { text: 'Herr', value: 'Herr', selected: false },
          { text: 'Frau', value: 'Frau', selected: false }
        ],
        first_name: '',
        last_name: '',
        url_1: '',
        url_2: '',
        heading: '',
        description: '',
        image: ''
      },
      showModal: false,
      modalEdit: false,
      showDelete: false,
      dataSource: new DataManager({
        url: 'https://fpk.sempre-diritto.de/api/list_cooks.php',
        adaptor: new UrlAdaptor,
        crossDomain: false
      }),
      toolbar: [
        { text: 'Hinzufügen', tooltipText: 'Einen Koch hinzufügen', prefixIcon: 'e-add', id: 'addClass' },
        'Search'
      ],
      sortSettings: {
        columns: [{
          field: 'last_name',
          direction: 'Ascending'
        }]
      },
      ColumnAttributes : {
        class: 'bold-column'
      }
    }
  },
  mounted: function() {
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('keyup', () => {
      this.$refs.grid.search((event.target).value)
    })
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('click', () => {
      this.$refs.grid.ej2Instances.searchSettings.key = '';
    })
    this.resetModel()
  },
  methods: {
    dataBound: function () {
      this.$refs.grid.autoFitColumns(['id', 'title', 'first_name', 'last_name'])
    },
    clickToolbar: function (args) {
      if (args.item.id === 'addClass') {
        this.showModal = true
        this.modalEdit = false
      }
    },
    selectRow (row) {
      axios.get('get_cook.php?id=' + row.data.id).then(response => {
        this.model = response.data
        this.showModal = true
        this.modalEdit = true
      })
    },
    closeModal () {
      this.showModal = false
      this.resetModel()
      this.$refs.grid.clearRowSelection()
    },
    saveModal(event) {
      this.$refs.title.validate()
      event.target.classList.add('was-validated')
      if (this.model.title !== '' && this.model.first_name !== '' && this.model.last_name !== '') {
        var url = ''
        if (this.modalEdit === true) {
          url = 'edit_cook.php'
        } else {
          url = 'add_cook.php'
        }
        axios({
          method: 'post',
          url: url,
          data: this.model
        }).then(response => {
          if (response.data.success === false) {
            alert(response.data.error)
          } else {
            this.$refs.grid.refresh()
            this.showModal = false
            this.resetModel()
          }
        }).catch(error => {
          alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
        })
      }
    },
    cancelEntry (event) {
      var url = 'delete_cook.php'
      axios({
        method: 'post',
        url: url,
        data: this.model
      }).then(response => {
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showDelete = false
          this.closeModal(event)
        }
      }).catch(error => {
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    resetModel () {
      this.model.id = 'new'
      this.model.title = [
        { text: 'Herr', value: 'Herr', selected: false },
        { text: 'Frau', value: 'Frau', selected: false }
      ]
      this.model.first_name = ''
      this.model.last_name = ''
      this.model.url_1 = ''
      this.model.url_2 = ''
      this.model.heading = ''
      this.model.description = ''
      this.model.image = ''
    }
  },
  provide: {
    grid: [Toolbar, Search, Resize, Sort]
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.e-grid .e-rowcell.bold-column {
  color: #79b51c !important;
  font-weight: 500;
  font-size: 16px;
}

</style>
