<template>
  <mdb-container fluid>
    <!-- Section: Grid -->
    <section style="height: calc(100vh - 62px);">
      <ejs-grid
        ref="grid"
        locale="de"
        height="100%"
        :dataSource="dataSource"
        :dataBound="dataBound"
        :queryCellInfo="queryCellInfo"
        :toolbar="toolbar"
        :toolbarClick="clickToolbar"
        :allowGrouping="true"
        :groupSettings="groupSettings"
        :allowSorting="true"
        :sortSettings="sortSettings"
        :rowSelected="selectRow"
      >
        <e-columns>
          <e-column
            field="id"
            headerText="ID"
            textAlign="Left"
            :visible="false"
          ></e-column>
          <e-column
            field="date"
            headerText="Datum"
            textAlign="Right"
            format="dd.MM.yyyy"
            type="date"
            :customAttributes="dateColumnAttributes"
          ></e-column>
          <e-column
            field="title"
            headerText="Kurs"
            textAlign="Left"
            :customAttributes="titleColumnAttributes"
          ></e-column>
          <e-column
            field="location_title"
            headerText="Ort"
            textAlign="Left"
          ></e-column>
          <e-column
            field="cook_full_name"
            headerText="Koch"
            textAlign="Left"
          ></e-column>
          <e-column
            field="day"
            headerText="Wochentag"
            textAlign="Left"
          ></e-column>
          <e-column
            field="start"
            headerText="Beginn"
            textAlign="Right"
            :allowSorting="false"
          ></e-column>
          <e-column
            field="end"
            headerText="Ende"
            textAlign="Right"
            :allowSorting="false"
          ></e-column>
          <e-column
            field="price"
            headerText="Einzelpreis"
            textAlign="Right"
            :allowSorting="false"
            :format="{ format:'C', currency:'EUR' }"
          ></e-column>
          <e-column
            field="capacity"
            headerText="Belegung"
            textAlign="Right"
            :customAttributes="capacityColumnAttributes"
            :allowSorting="false"
          ></e-column>
          <e-column
            field="status"
            headerText="Kursstatus"
            textAlign="Left"
            width="200"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </section>
    <!-- /Section: Grid -->
    <!-- Modal: Class -->
    <mdb-modal
      side
      position="right"
      direction="right"
      full-height
      size="lg"
      :show="showModal"
      @close="closeModal"
    >
      <mdb-modal-header>
        <mdb-modal-title v-if="modalEdit">Kurs bearbeiten</mdb-modal-title>
        <mdb-modal-title v-else>Kurs hinzufügen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <form id="modal-form" novalidate @submit.prevent="saveModal" @keydown.enter.prevent>
          <mdb-row>
            <mdb-col md="6" class="mb-2">
              <legend><mdb-icon icon="calendar-alt" class="light-green-text" /> Kurs</legend>
              <input type="hidden" v-model="model.id">
              <mdb-date-picker
                label="Datum"
                v-model="model.date"
                :option="datePickerOptions"
                :validation="wasValidated"
                :isValid="isDateValid"
                @getValue="handleDateChange"
                disableClear
              />
              <mdb-input
                label="Beginn"
                type="time"
                v-model="model.start"
                class="padding-label"
                required
              />
              <mdb-input
                label="Ende"
                type="time"
                v-model="model.end"
                class="padding-label"
                required
              />
              <mdb-input
                label="Kurstitel"
                type="text"
                v-model="model.title"
                required
              />
              <mdb-select
                label="Ort"
                v-model="model.location"
                ref="location"
                validation
                search
              />
              <mdb-select
                label="Koch"
                v-model="model.cook"
                ref="cook"
                validation
                search
              />
              <mdb-input
                label="Plätze"
                type="number"
                v-model="model.slots"
                required
              />
              <mdb-input
                label="Preis (€)"
                type="text"
                v-model="model.price"
                required
              />
            </mdb-col>
            <mdb-col md="6" class="mb-2">
              <legend><mdb-icon icon="search" fas class="light-green-text" /> Details</legend>
              <mdb-select
                label="Tags"
                v-model="model.keywords"
                multiple
                :visibleOptions="6"
              />
              <mdb-input
                label="Beschreibung"
                type="textarea"
                v-model="model.description"
                :rows="5"
              />
              <mdb-input
                label="Bemerkung"
                type="textarea"
                v-model="model.remark"
                :rows="5"
              />
            </mdb-col>
          </mdb-row>
          <mdb-row>
            <mdb-col md="6" class="mb-2">
              <legend><mdb-icon icon="info-circle" fas class="light-green-text" /> Status</legend>
              <mdb-input type="radio" id="status-1" name="Kursstatus" radioValue="passiv" v-model="model.status" label="passiv" checked />
              <mdb-input type="radio" id="status-2" name="Kursstatus" radioValue="aktiv" v-model="model.status" label="aktiv" />
              <mdb-input type="radio" id="status-3" name="Kursstatus" radioValue="ausgebucht" v-model="model.status" label="ausgebucht" />
            </mdb-col>
            <mdb-col md="6" class="mb-2" v-if="modalEdit">
              <legend><mdb-icon icon="angle-double-right" fas class="light-green-text" /> Aktionen</legend>
              <mdb-btn color="light-green" @click.native="printParticipants"><mdb-icon icon="print" fas class="mr-1"/> Teilnehmerliste</mdb-btn>
              <mdb-btn color="light-green" @click.native="showMail = true"><mdb-icon icon="envelope" fas class="mr-1"/> E-Mail an Teilnehmer</mdb-btn>
              <mdb-btn color="danger" @click.native="showCancel = true"><mdb-icon icon="ban" fas class="mr-1"/> Kurs absagen</mdb-btn>
            </mdb-col>
          </mdb-row>
        </form>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="light-green" @click.native="closeModal">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="light-green" v-if="modalEdit">Speichern</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="light-green" v-else>Hinzufügen</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Class -->
    <!-- Modal: Cancel -->
    <mdb-modal size="lg" :show="showCancel" @close="showCancel = false" danger>
      <mdb-modal-header>
        <p class="heading"><strong>Kurs absagen</strong></p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="ban" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie den gewählten Kurs wirklich absagen?</strong>
            </p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-title>Hinweis</mdb-card-title>
                <mdb-card-text>
                  <p>Der Kursstatus wird auf passiv gesetzt, die Buchungen bleiben erhalten.</p>
                  <p>Bitte geben Sie eine Nachricht für die Absage ein, die den Teilnehmern per E-Mail zugesendet wird:</p>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
            <mdb-card class="mt-4">
              <mdb-card-body>
                <mdb-card-text>
                  <form id="cancel-form" novalidate @submit.prevent="cancelEntry">
                    <mdb-input
                      label="Nachricht"
                      type="textarea"
                      v-model="mail.message"
                      class="red-textarea"
                      :rows="10"
                      required
                    />
                  </form>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="danger" @click="showCancel = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="cancel-form" color="danger">Absagen</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Cancel -->
    <!-- Modal: Mail -->
    <mdb-modal size="lg" :show="showMail" @close="showMail = false" danger>
      <mdb-modal-header color="light-green">
        <p class="heading"><strong>Mitteilung versenden</strong></p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="paper-plane" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Bitte geben Sie eine Nachricht ein, welche an die Kursteilnehmer versendet werden soll:</strong>
            </p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-text>
                  <form id="mail-form" novalidate @submit.prevent="sendMail">
                    <mdb-input
                      label="Nachricht"
                      type="textarea"
                      v-model="mail.message"
                      class="green-textarea"
                      :rows="10"
                      required
                    />
                  </form>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="light-green" @click="showMail = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="mail-form" color="light-green">Senden</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Mail -->
    <!-- Modal: importClasses -->
    <mdb-modal
      centered
      size="lg"
      scrollable
      removeBackdrop
      :show="showImportClasses"
      @close="showImportClasses = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>Kurse importieren</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="2" class="mb-3">
            <mdb-icon icon="file-import" fas size="4x" class="light-green-text ml-4"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              Wählen sie eine Excel-Datei aus die importiert werden soll:
            </p>
            <input type="file" @change="handleFileUpload( $event )"/>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="light-green" @click="showImportClasses = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="light-green" @click.native="importClasses">Importieren</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: importClasses -->
  </mdb-container>
</template>

<script>
import Vue from 'vue'
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json'
import * as cagregorian from 'cldr-data/main/de/ca-gregorian.json'
import * as numbers from 'cldr-data/main/de/numbers.json'
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json'
import * as weekData from 'cldr-data/supplemental/weekData.json'
import * as currencies from 'cldr-data/main/de/currencies.json'
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base'
import { GridPlugin, Resize, Toolbar, Sort, Search, Group } from '@syncfusion/ej2-vue-grids'
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data'
import axios from 'axios'
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbIcon,
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbInput,
  mdbSelect,
  mdbCard,
  mdbCardBody,
  mdbCardTitle,
  mdbCardText,
  mdbDatePicker
} from 'mdbvue'

L10n.load({
  'de': {
    'grid': {
      'EmptyRecord': 'Keine Einträge gefunden',
      'GroupDropArea': 'Spalte hier ablegen um zu gruppieren',
      'UnGroup': 'Klicken Sie hier, um die Gruppierung aufheben',
      'Item': 'Eintrag',
      'Items': 'Einträge',
      'Search': 'Suche',
      'FilterButton': 'Filtern',
      'ClearButton': 'Löschen',
      'SelectAll': 'Alle',
      'Blanks': 'Leer',
      'Add': 'Hinzufügen',
      'Edit': 'Bearbeiten',
      'Delete': 'Löschen',
      'Enter the value': 'Suchbegriff eingeben'
    },
    'pager': {
      'currentPageInfo': '{0} von {1} Seiten',
      'totalItemsInfo': '({0} Einträge)',
      'firstPageTooltip': 'Zur ersten Seite',
      'lastPageTooltip': 'Zur letzten Seite',
      'nextPageTooltip': 'Zur nächsten Seite',
      'previousPageTooltip': 'Zurück zur letzten Seit',
      'nextPagerTooltip': 'Zum nächsten Pager',
      'previousPagerTooltip': 'Zum vorherigen Pager'
    }
  }
})
loadCldr(numberingSystems, cagregorian, numbers, timeZoneNames, weekData, currencies)
setCulture('de')
setCurrencyCode('EUR')

window.axios = axios
axios.defaults.baseURL = 'https://fpk.sempre-diritto.de/api/'

Vue.use(GridPlugin)

export default {
  name: 'Classes',
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
    mdbSelect,
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText,
    mdbDatePicker
  },
  data () {
    return {
      model: {
        id: 'new',
        title: '',
        description: '',
        remark: '',
        keywords: [],
        location: [],
        cook: [],
        date: '',
        start: '',
        end: '',
        slots: '',
        price: '',
        status: 'passiv'
      },
      mail: {
        class: '',
        message: '',
      },
      tags: [
        {
          text: 'Fisch',
          value: 'Fisch'
        },
        {
          text: 'Fleisch',
          value: 'Fleisch'
        },
        {
          text: 'vegan/vegetarisch',
          value: 'vegan/vegetarisch'
        },
        {
          text: 'Länderküche',
          value: 'Länderküche'
        },
        {
          text: 'Carlsplatz',
          value: 'Carlsplatz'
        }
      ],
      showModal: false,
      modalEdit: false,
      showCancel: false,
      showMail: false,
      showImportClasses: false,
      wasValidated: false,
      isDateValid: false,
      dataSource: new DataManager({
        url: 'https://fpk.sempre-diritto.de/api/list_classes.php',
        adaptor: new UrlAdaptor,
        crossDomain: false
      }),
      toolbar: [
        { text: 'Hinzufügen', tooltipText: 'Einen Kurs hinzufügen', prefixIcon: 'e-add', id: 'addClass' },
        { text: 'Importieren', tooltipText: 'Kurse über Excel-Datei importieren', prefixIcon: 'e-icon-upload-1', id: 'importClasses' },
        'Search'
      ],
      groupSettings: { 
        captionTemplate: "<div>${format(data)}</div>"
      },
      formatOptions: {
        type: 'date',
        format: 'dd.MM.yyyy'
      },
      sortSettings: {
        columns: [{
          field: 'date',
          direction: 'Ascending'
        }]
      },
      datePickerOptions: {
        format: 'DD.MM.YYYY',
        week: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
        month: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        placeholder: '',
        buttons: {
          ok: 'Speichern',
          clear: 'Zurücksetzen',
          today: 'Heute'
        }
      },
      dateColumnAttributes : {
        class: 'date-column'
      },
      titleColumnAttributes : {
        class: 'title-column'
      },
      capacityColumnAttributes : {
        class: 'capacity-column'
      }
    }
  },
  watch: {
    showMail: function () {
      if (this.showMail == false) {
        this.mail.class = ''
        this.mail.message = ''
      }
      if (this.showMail == true) {
        this.mail.class = this.model.id
      }
    },
    showCancel: function () {
      if (this.showCancel == false) {
        this.mail.class = ''
        this.mail.message = ''
      }
      if (this.showCancel == true) {
        this.mail.class = this.model.id
      }
    }
  },
  mounted: function() {
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('keyup', () => {
      this.$refs.grid.search((event.target).value)
    })
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('click', () => {
      this.$refs.grid.ej2Instances.searchSettings.key = '';
    })
    this.resetModel()
  },
  methods: {
    dataBound: function () {
      this.$refs.grid.autoFitColumns(['date', 'title', 'location_title', 'cook_full_name', 'day', 'start', 'end', 'price', 'capacity'])
    },
    actionComplete: function (e) {
      if (e.requestType === 'grouping' || e.requestType === 'searchingss') {
        this.$refs.grid.ej2Instances.groupModule.collapseAll()
      }
    },
    queryCellInfo: function (args) {
      if (args.column.field === 'status') {
        if (args.data['status'] === 'ausgebucht') {
          args.cell.classList.add('status-circle', 'status-blue')
        } else if (args.data['status'] === 'passiv') {
          args.cell.classList.add('status-circle', 'status-grey')
        } else {
          args.cell.classList.add('status-circle', 'status-green')
        }
      }
    },
    clickToolbar: function (args) {
      if (args.item.id === 'addClass') {
        this.showModal = true
        this.modalEdit = false
      }
      if (args.item.id === 'importClasses') {
        this.showImportClasses = true
      }
    },
    selectRow (row) {
      axios.get('get_class.php?id=' + row.data.id).then(response => {
        this.model = response.data
        this.showModal = true
        this.modalEdit = true
      })
    },
    closeModal () {
      this.showModal = false
      this.resetModel()
      this.$refs.grid.clearRowSelection()
    },
    saveModal(event) {
      this.wasValidated = true
      this.$refs.location.validate()
      this.$refs.cook.validate()
      event.target.classList.add('was-validated')
      if (this.model.date !== '' && this.model.start !== '' && this.model.end !== '' && this.model.title !== '' && this.model.location !== '' && this.model.cook !== '' && this.model.slots !== '' && this.model.price !== '') {
        var url = ''
        if (this.modalEdit === true) {
          url = 'edit_class.php'
        } else {
          url = 'add_class.php'
        }
        axios({
          method: 'post',
          url: url,
          data: this.model
        }).then(response => {
          if (response.data.success === false) {
            alert(response.data.error)
          } else {
            this.$refs.grid.refresh()
            this.showModal = false
            this.resetModel()
          }
        }).catch(error => {
          alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
        })
      }
    },
    cancelEntry () {
      if (this.mail.message !== '') {
        var url = 'cancel_class.php'
        axios({
          method: 'post',
          url: url,
          data: this.mail
        }).then(response => {
          if (response.data.success === false) {
            alert(response.data.error)
          } else {
            this.model.status = response.data.status
            this.$refs.grid.refresh()
            this.showCancel = false
          }
        }).catch(error => {
          alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
        })
      }
    },
    handleFileUpload (event) {
      this.file = event.target.files[0]
    },
    sendMail () {
      if (this.mail.message !== '') {
        var url = 'send_mail.php'
        axios({
          method: 'post',
          url: url,
          data: this.mail
        }).then(response => {
          if (response.data.success === false) {
            alert(response.data.error)
          } else {
            this.showMail = false
          }
        }).catch(error => {
          alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
        })
      }
    },
    printParticipants () {
      window.open('https://fpk.sempre-diritto.de/api/print_participants.php?id=' + this.model.id, '_blank');
    },
    importClasses () {
      let formData = new FormData()
      formData.append('file', this.file)
      axios.post( 'import_classes.php',
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      }).then(response => {
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showImportClasses = false
        }
      }).catch(error => {
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    resetModel () {
      this.wasValidated = false
      this.model.id = 'new'
      this.model.title = ''
      this.model.description = ''
      this.model.remark = ''
      this.model.keywords = []
      axios.get('list_keywords.php').then(response => {
        // var self = this
        // self.model.keywords = response

        this.model.keywords = response.data

        // console.log(response)
        // var self = this
        // self.model.keywords = response.data.result
        // console.log(self.model.keywords)

        // var self = this
        // response.forEach(function(keyword) {
        //   var row = { text: keyword.text, value: keyword.value, selected: false }
        //   self.model.keywords.push(row);
        // })
      })
      this.model.location = []
      axios.get('list_locations.php').then(response => {
        var self = this
        response.data.result.forEach(function(location) {
          var row = { text: location.title, value: location.id, selected: false }
          self.model.location.push(row);
        })
      })
      this.model.cook = []
      axios.get('list_cooks.php').then(response => {
        var self = this
        response.data.result.forEach(function(cook) {
          var row = { text: cook.first_name + ' ' + cook.last_name, value: cook.id, selected: false }
          self.model.cook.push(row);
        })
      })
      this.model.date = ''
      this.model.start = ''
      this.model.end = ''
      this.model.slots = ''
      this.model.price = ''
      this.model.status = 'passiv'
    },
    handleDateChange(value) {
      this.model.date = value;
      this.$nextTick(this.validate);
    },
    validate() {
      if (this.model.date !== "") {
        this.isDateValid = true
      } else {
        this.isDateValid = false
      }
    },
    getTagValue() {
      // var tagString = ''
      // for (var tag in tags) {
      //   tagString += tag + ', '
      // }
      // tagString = tagString.substring(0, tagString.length - 2)
      // this.model.keywords = tagString
      // console.log(this.model.keywords)
      console.log(this.model.keywords)
    }
  },
  provide: {
    grid: [Toolbar, Search, Resize, Sort, Group]
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.e-grid .e-rowcell.date-column {
  color: #79b51c !important;
  font-weight: bold;
  font-size: 15px;
}

.e-grid .e-rowcell.title-column {
  font-weight: normal;
  font-size: 16px;
}

.e-grid .e-rowcell.capacity-column {
  font-weight: 500;
  font-size: 16px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}

.padding-label label {
  margin-bottom: 0px;
  margin-top: 14px;
}

.red-textarea.md-form label.active {
  color: #ff3547;
}

.red-textarea textarea.md-textarea:focus:not([readonly]),
.active-red-textarea textarea.md-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #ff3547;
  box-shadow: 0 1px 0 0 #ff3547;
}

.red-textarea.md-form .prefix.active {
  color: #ff3547;
}

.red-textarea.md-form textarea.md-textarea:focus:not([readonly])+label {
  color: #ff3547;
}

.green-textarea.md-form label.active {
  color: #8bc34a;
}

.green-textarea textarea.md-textarea:focus:not([readonly]),
.active-green-textarea textarea.md-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #8bc34a;
  box-shadow: 0 1px 0 0 #8bc34a;
}

.green-textarea.md-form .prefix.active {
  color: #8bc34a;
}

.green-textarea.md-form textarea.md-textarea:focus:not([readonly])+label {
  color: #8bc34a;
}

.active-red-textarea textarea.md-textarea {
  border-bottom: 1px solid #ff3547;
  box-shadow: 0 1px 0 0 #ff3547;
}

.active-red-textarea.md-form label.active {
  color: #ff3547;
}

.active-red-textarea.md-form label {
  color: #ff3547;
}

.active-red-textarea.md-form .prefix {
  color: #ff3547;
}

.active-red-textarea.md-form textarea.md-textarea:focus:not([readonly])+label {
  color: #ff3547;
}

.active-green-textarea textarea.md-textarea {
  border-bottom: 1px solid #8bc34a;
  box-shadow: 0 1px 0 0 #8bc34a;
}

.active-green-textarea.md-form label.active {
  color: #8bc34a;
}

.active-green-textarea.md-form label {
  color: #8bc34a;
}

.active-green-textarea.md-form .prefix {
  color: #8bc34a;
}

.active-green-textarea.md-form textarea.md-textarea:focus:not([readonly])+label {
  color: #8bc34a;
}

</style>
