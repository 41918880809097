<template>
  <mdb-container fluid>
    <!-- Section: Grid -->
    <section style="height: calc(100vh - 62px);">
      <ejs-grid
        ref="grid"
        locale="de"
        height="100%"
        :dataSource="dataSource"
        :dataBound="dataBound"
        :toolbar="toolbar"
        :toolbarClick="clickToolbar"
        :allowSorting="true"
        :sortSettings="sortSettings"
        :rowSelected="selectRow"
      >
        <e-columns>
          <e-column
            field="id"
            headerText="ID"
            textAlign="Left"
            :visible="false"
          ></e-column>
          <e-column
            field="date"
            headerText="Ausstellungsdatum"
            textAlign="Right"
            format="dd.MM.yyyy HH:mm:ss"
            type="date"
          ></e-column>
          <e-column
            field="expiration"
            headerText="Ablaufdatum"
            textAlign="Right"
            format="dd.MM.yyyy"
            type="date"
            :customAttributes="dateColumnAttributes"
          ></e-column>
          <e-column
            field="code"
            headerText="Code"
            textAlign="Left"
            :allowSorting="false"
            :customAttributes="codeColumnAttributes"
          ></e-column>
          <e-column
            field="value_total"
            headerText="Wert"
            textAlign="Right"
            :allowSorting="false"
            :format="{ format:'C', currency:'EUR' }"
          ></e-column>
          <e-column
            field="value_left"
            headerText="Restwert"
            textAlign="Right"
            :allowSorting="false"
            :format="{ format:'C', currency:'EUR' }"
          ></e-column>
          <e-column
            field="value_hidden"
            headerText="Kursgutschein"
            textAlign="Right"
          ></e-column>
          <e-column
            field="redeemed_date"
            headerText="Einlösedatum"
            textAlign="Right"
            format="dd.MM.yyyy"
            type="date"
          ></e-column>
          <e-column
            field="redeemed_client_full_name"
            headerText="Kunde"
            textAlign="Left"
          ></e-column>
          <e-column
            field="redeemed_class_title"
            headerText="Kurs"
            textAlign="Left"
          ></e-column>
          <e-column
            field="status"
            headerText="Status"
            textAlign="Left"
            width="200"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </section>
    <!-- /Section: Grid -->
    <!-- Modal: Coupons -->
    <mdb-modal
      side
      position="right"
      direction="right"
      full-height
      size="lg"
      :show="showModal"
      @close="closeModal"
    >
      <mdb-modal-header>
        <mdb-modal-title v-if="modalEdit">Gutschein verwalten</mdb-modal-title>
        <mdb-modal-title v-else>Gutschein erstellen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <form id="modal-form" novalidate @submit.prevent="saveModal" @keydown.enter.prevent>
          <mdb-row v-if="modalEdit">
            <mdb-col md="6" class="mb-2">
              <legend><mdb-icon icon="tag" fas class="light-green-text" /> Gutschein</legend>
              <mdb-input
                label="Ausstellungsdatum"
                type="text"
                v-model="model.date"
                disabled
              />
              <mdb-input
                label="Ablaufdatum"
                type="text"
                v-model="model.expiration"
                disabled
              />
              <mdb-input
                label="Code"
                type="text"
                v-model="model.code"
                inputClass="text-monospace"
                disabled
              />
              <mdb-input
                label="Wert (€)"
                type="text"
                v-model="model.value_total"
                disabled
              />
              <mdb-input
                label="Wert ausgegeben (€)"
                type="text"
                v-model="model.value_spent"
                disabled
              />
              <mdb-input
                label="Wert übrig (€)"
                type="text"
                v-model="model.value_left"
                disabled
              />
            </mdb-col>
            <mdb-col md="6" class="mb-2">
              <legend><mdb-icon icon="search" fas class="light-green-text" /> Details</legend>
              <mdb-input
                label="Von"
                type="text"
                v-model="model.sender_name"
                disabled
              />
              <mdb-input
                label="Für"
                type="text"
                v-model="model.recipient_name"
                disabled
              />
              <mdb-input
                label="Betreff"
                type="text"
                v-model="model.recipient_subject"
                disabled
              />
              <mdb-input
                label="Versendet an"
                type="text"
                v-model="model.recipient"
                disabled
              />
              <legend><mdb-icon icon="history" fas class="light-green-text" /> Verlauf</legend>
              <span v-html="model.history"></span>
              <!--<mdb-input
                label="Ereignisse"
                type="textarea"
                v-model="model.history"
                disabled
                :rows="6"
              />
              <mdb-input
                label="Eingelöst am"
                type="text"
                v-model="model.redeemed_date"
                disabled
              />
              <mdb-input
                label="Eingelöst von"
                type="text"
                v-model="model.redeemed_client_full_name"
                disabled
              />
              <mdb-input
                label="Eingelöst für"
                type="text"
                v-model="model.redeemed_class_title"
                disabled
              />
              <mdb-input
                label="Zuletzt versendet an"
                type="text"
                v-model="model.recipient"
                disabled
              />-->
            </mdb-col>
          </mdb-row>
          <mdb-row v-if="modalEdit">
            <mdb-col md="6" class="mb-2">
              <legend><mdb-icon icon="eye" fas class="light-green-text" /> Kursgutschein</legend>
              <mdb-input type="radio" id="hidden-1" name="Kursgutschein" radioValue="0" v-model="model.value_hidden" label="Nein" disabled />
              <mdb-input type="radio" id="hidden-2" name="Kursgutschein" radioValue="1" v-model="model.value_hidden" label="Ja" disabled />
              <legend class="mt-4"><mdb-icon icon="info-circle" fas class="light-green-text" /> Status</legend>
              <mdb-input type="radio" id="status-1" name="Couponstatus" radioValue="offen" v-model="model.status" label="offen" disabled />
              <mdb-input type="radio" id="status-2" name="Couponstatus" radioValue="teileingelöst" v-model="model.status" label="teileingelöst" disabled />
              <mdb-input type="radio" id="status-3" name="Couponstatus" radioValue="eingelöst" v-model="model.status" label="eingelöst" disabled />
            </mdb-col>
            <mdb-col md="6" class="mb-2">
              <legend><mdb-icon icon="angle-double-right" fas class="light-green-text" /> Aktionen</legend>
              <mdb-btn color="light-green" @click.native="showSend = true"><mdb-icon icon="paper-plane" fas class="mr-1"/> Gutschein versenden</mdb-btn>
              <div class="w-100"></div>
              <mdb-btn color="light-green" @click.native="printEntry"><mdb-icon icon="print" fas class="mr-1"/> Gutschein drucken</mdb-btn>
              <div class="w-100"></div>
              <mdb-btn color="light-green" @click.native="showPayment = true"><mdb-icon icon="credit-card" fas class="mr-1"/> Restwert hinzufügen/entfernen</mdb-btn>
              <div class="w-100"></div>
              <mdb-btn color="danger" @click.native="showDelete = true"><mdb-icon icon="ban" fas class="mr-1"/> Gutschein löschen</mdb-btn>
            </mdb-col>
          </mdb-row>
          <mdb-row v-else>
            <mdb-col md="6" class="mb-2">
              <legend><mdb-icon icon="tag" fas class="light-green-text" /> Gutschein</legend>
              <mdb-input
                label="Code"
                type="text"
                v-model="model.code"
                inputClass="text-monospace"
                required
                disabled
              />
              <mdb-input
                label="Wert (€)"
                type="text"
                v-model="model.value_total"
                required
              />
              <legend><mdb-icon icon="eye" fas class="light-green-text" /> Wert verstecken</legend>
              <mdb-switch color="light-green-text" onLabel="Ja" offLabel="Nein" v-model="model.value_hidden" />
            </mdb-col>
            <mdb-col md="6" class="mb-2">
              <legend><mdb-icon icon="search" fas class="light-green-text" /> Details</legend>
              <mdb-input
                label="Von"
                type="text"
                v-model="model.sender_name"
              />
              <mdb-input
                label="Für"
                type="text"
                v-model="model.recipient_name"
              />
              <mdb-input
                label="Betreff"
                type="text"
                v-model="model.recipient_subject"
              />
            </mdb-col>
          </mdb-row>
        </form>
      </mdb-modal-body>
      <mdb-modal-footer v-if="modalEdit">
        <mdb-btn color="light-green" @click.native="closeModal">Schließen</mdb-btn>
      </mdb-modal-footer>
      <mdb-modal-footer v-else>
        <mdb-btn outline="light-green" @click.native="closeModal">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="light-green">Erstellen</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Edit -->
    <!-- Modal: Delete -->
    <mdb-modal size="lg" :show="showDelete" @close="showDelete = false" danger>
      <mdb-modal-header>
        <p class="heading"><strong>Gutschein löschen</strong></p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="ban" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie den gewählten Gutschein wirklich löschen?</strong>
            </p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-title>Hinweis</mdb-card-title>
                <mdb-card-text>
                  <p>Dieser Vorgang schlägt fehl, falls der Gutschein bereits eingelöst wurde.</p>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="danger" @click="showDelete = false">Abbrechen</mdb-btn>
        <mdb-btn form="modal-form" color="danger" @click.native="cancelEntry">Löschen</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Delete -->
    <!-- Modal: Send -->
    <mdb-modal size="lg" :show="showSend" @close="showSend = false" danger>
      <mdb-modal-header color="light-green">
        <p class="heading"><strong>Gutschein senden</strong></p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="paper-plane" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>An welche E-Mail Adresse soll der Gutschein versendet werden?</strong>
            </p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-text>
                  <form id="send-form" novalidate @submit.prevent="sendEntry" @keydown.enter.prevent>
                    <mdb-input
                      label="E-Mail"
                      type="email"
                      v-model="recipient"
                      required
                    />
                  </form>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="light-green" @click="showSend = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="send-form" color="light-green">Senden</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Send -->
    <!-- Modal: Payment -->
    <mdb-modal size="lg" :show="showPayment" @close="showPayment = false" danger>
      <mdb-modal-header color="light-green">
        <p class="heading"><strong>Restwert hinzufügen/entfernen</strong></p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="credit-card" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Bitte geben Sie den Betrag an, der hinzugefügt oder abgezogen werden soll:</strong>
            </p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-text>
                  <form id="payment-form" novalidate @submit.prevent="changePayment" @keydown.enter.prevent>
                    <mdb-input
                      label="Grund"
                      type="text"
                      v-model="payment.subject"
                      required
                    />
                    <mdb-input
                      label="Summe (€)"
                      type="text"
                      v-model="payment.sum"
                      required
                    />
                    <mdb-input type="radio" id="payment-type-1" name="Zahlungsstatus" radioValue="hinzufügen" v-model="payment.type" label="Restwert hinzufügen" />
                    <mdb-input type="radio" id="payment-type-2" name="Zahlungsstatus" radioValue="entfernen" v-model="payment.type" label="Restwert entfernen" checked />
                  </form>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="light-green" @click="showPayment = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="payment-form" color="light-green">Ändern</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Payment -->
  </mdb-container>
</template>

<script>
import Vue from 'vue'
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json'
import * as cagregorian from 'cldr-data/main/de/ca-gregorian.json'
import * as numbers from 'cldr-data/main/de/numbers.json'
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json'
import * as weekData from 'cldr-data/supplemental/weekData.json'
import * as currencies from 'cldr-data/main/de/currencies.json'
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base'
import { GridPlugin, Resize, Toolbar, Sort, Search } from '@syncfusion/ej2-vue-grids'
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data'
import axios from 'axios'
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbIcon,
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbInput,
  mdbCard,
  mdbCardBody,
  mdbCardTitle,
  mdbCardText,
  mdbSwitch
} from 'mdbvue'

L10n.load({
  'de': {
    'grid': {
      'EmptyRecord': 'Keine Einträge gefunden',
      'GroupDropArea': 'Spalte hier ablegen um zu gruppieren',
      'UnGroup': 'Klicken Sie hier, um die Gruppierung aufheben',
      'Item': 'Eintrag',
      'Items': 'Einträge',
      'Search': 'Suche',
      'FilterButton': 'Filtern',
      'ClearButton': 'Löschen',
      'SelectAll': 'Alle',
      'Blanks': 'Leer',
      'Add': 'Hinzufügen',
      'Edit': 'Bearbeiten',
      'Delete': 'Löschen',
      'Enter the value': 'Suchbegriff eingeben'
    },
    'pager': {
      'currentPageInfo': '{0} von {1} Seiten',
      'totalItemsInfo': '({0} Einträge)',
      'firstPageTooltip': 'Zur ersten Seite',
      'lastPageTooltip': 'Zur letzten Seite',
      'nextPageTooltip': 'Zur nächsten Seite',
      'previousPageTooltip': 'Zurück zur letzten Seit',
      'nextPagerTooltip': 'Zum nächsten Pager',
      'previousPagerTooltip': 'Zum vorherigen Pager'
    }
  }
})
loadCldr(numberingSystems, cagregorian, numbers, timeZoneNames, weekData, currencies)
setCulture('de')
setCurrencyCode('EUR')

window.axios = axios
axios.defaults.baseURL = 'https://fpk.sempre-diritto.de/api/'

Vue.use(GridPlugin)

export default {
  name: 'Coupons',
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText,
    mdbSwitch
  },
  data () {
    return {
      model: {
        id: 'new',
        date: '0000-00-00 00:00:00',
        code: '',
        sender_name: '',
        recipient: '-',
        recipient_name: '',
        recipient_subject: '',
        value_total: '',
        value_spent: '0,00',
        value_left: '0,00',
        value_hidden: 0,
        redeemed_date: '0000-00-00 00:00:00',
        redeemed_client_full_name: '-',
        redeemed_class_title: '-',
        history: '',
        status: 'offen'
      },
      payment: {
        id: '',
        subject: '',
        sum: '0,00',
        type: 'entfernen'
      },
      recipient: '',
      chars: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
      showModal: false,
      modalEdit: false,
      showSend: false,
      showPrint: false,
      showDelete: false,
      showPayment: false,
      dataSource: new DataManager({
        url: 'https://fpk.sempre-diritto.de/api/list_coupons.php',
        adaptor: new UrlAdaptor,
        crossDomain: false
      }),
      toolbar: [
        { text: 'Hinzufügen', tooltipText: 'Einen Gutschein hinzufügen', prefixIcon: 'e-add', id: 'addClass' },
        'Search'
      ],
      sortSettings: {
        columns: [{
          field: 'date',
          direction: 'Descending'
        }]
      },
      dateColumnAttributes : {
        class: 'date-column'
      },
      codeColumnAttributes : {
        class: 'code-column'
      }
    }
  },
  watch: {
    showPayment: function () {
      if (this.showPayment == false) {
        this.payment.subject = ''
        this.payment.sum = '0,00'
        this.payment.type = 'entfernen'
      }
      if (this.showPayment == true) {
        this.payment.id = this.model.id
      }
    }
  },
  mounted: function() {
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('keyup', () => {
      this.$refs.grid.search((event.target).value)
    })
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('click', () => {
      this.$refs.grid.ej2Instances.searchSettings.key = '';
    })
    this.resetModel()
  },
  methods: {
    dataBound: function () {
      this.$refs.grid.autoFitColumns(['date', 'expiration', 'code', 'value_total', 'value_left', 'value_hidden', 'redeemed_date', 'redeemed_client_full_name', 'redeemed_class_title'])
    },
    clickToolbar: function (args) {
      if (args.item.id === 'addClass') {
        this.showModal = true
        this.modalEdit = false
        this.model.code = this.addRandomStrings()
      }
    },
    selectRow (row) {
      axios.get('get_coupon.php?id=' + row.data.id).then(response => {
        this.model = response.data
        if (this.model.recipient != '-') {
          this.recipient = this.model.recipient
        }
        this.showModal = true
        this.modalEdit = true
      })
    },
    closeModal () {
      this.showModal = false
      this.resetModel()
      this.$refs.grid.clearRowSelection()
    },
    saveModal(event) {
      event.target.classList.add('was-validated')
      if (this.model.code !== '' && this.model.value_total !== '') {
        var url = ''
        if (this.modalEdit === true) {
          url = 'edit_coupon.php'
        } else {
          url = 'add_coupon.php'
        }
        axios({
          method: 'post',
          url: url,
          data: this.model
        }).then(response => {
          if (response.data.success === false) {
            alert(response.data.error)
          } else {
            this.$refs.grid.refresh()
            this.showModal = false
            this.resetModel()
          }
        }).catch(error => {
          alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
        })
      }
    },
    cancelEntry (event) {
      var url = 'delete_coupon.php'
      axios({
        method: 'post',
        url: url,
        data: this.model
      }).then(response => {
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showDelete = false
          this.closeModal(event)
        }
      }).catch(error => {
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    sendEntry (event) {
      event.target.classList.add('was-validated')
      if (this.recipient !== '') {
        var url = 'send_coupon.php'
        this.model.recipient = this.recipient
        axios({
          method: 'post',
          url: url,
          data: this.model
        }).then(response => {
          if (response.data.success === false) {
            alert(response.data.error)
          } else {
            this.$refs.grid.refresh()
            this.showSend = false
          }
        }).catch(error => {
          alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
        })
      }
    },
    printEntry () {
      window.open('https://fpk.sempre-diritto.de/api/print_coupon.php?id=' + this.model.id, '_blank');
    },
    changePayment (event) {
      event.target.classList.add('was-validated')
      if (this.payment.subject !== '' && this.payment.sum !== '') {
        var url = 'change_coupon.php'
        axios({
          method: 'post',
          url: url,
          data: this.payment
        }).then(response => {
          if (response.data.success === false) {
            alert(response.data.error)
          } else {
            this.model.value_spent = response.data.value_spent
            this.model.value_left = response.data.value_left
            this.model.history = response.data.history
            this.model.status = response.data.status
            this.$refs.grid.refresh()
            this.showPayment = false
          }
        }).catch(error => {
          alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
        })
      }
    },
    resetModel () {
      this.model.id = 'new'
      this.model.date = '0000-00-00 00:00:00'
      this.model.code = ''
      this.model.sender_name = ''
      this.model.recipient = '-'
      this.model.recipient_name = ''
      this.model.recipient_subject = ''
      this.model.value_total = ''
      this.model.value_spent = '0,00'
      this.model.value_left = '0,00'
      this.model.value_hidden = 0
      this.model.redeemed_date = '0000-00-00 00:00:00'
      this.model.redeemed_client_full_name = '-'
      this.model.redeemed_class_title = '-'
      this.model.history = ''
      this.model.status = 'offen'
      this.recipient = ''
    },
    generateString() {
      var result = ''
      for (var i = 0; i < 4; i++) {
        result += this.chars[Math.floor(Math.random() * 36)]
      }
      return result
    },
    addRandomStrings() {
      var string = ''
      for (var i = 0; i < 2; i++) {
        if (string === '') {
          string += this.generateString()
        } else {
          string += '-' + this.generateString()
        }
      }
      return string
    }
  },
  provide: {
    grid: [Toolbar, Search, Resize, Sort]
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.e-grid .e-rowcell.date-column {
  color: #79b51c !important;
  font-weight: bold;
  font-size: 15px;
}

.e-grid .e-rowcell.code-column {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-weight: normal;
  font-size: 16px;
}
</style>
