<template>
  <mdb-navbar class="flexible-navbar white" position="top" double scrolling>
    <mdb-navbar-brand @click.native="toggleSideNav" class="button-collapse"><mdb-icon icon="bars"/></mdb-navbar-brand>
    <mdb-navbar-nav left>
      <mdb-breadcrumb>{{computedPage.activePage}}</mdb-breadcrumb>
    </mdb-navbar-nav>
    <mdb-navbar-toggler/>
    <mdb-navbar-nav class="right-nav" right>
      <mdb-nav-item v-on:click="resetDatabase()">
        <mdb-icon icon="redo" class="light-green-text" /><span class="clearfix d-none d-sm-inline-block text-uppercase">Zurücksetzen</span>
      </mdb-nav-item>
      <mdb-nav-item>
        <mdb-icon icon="sign-out-alt" class="light-green-text" /><span class="clearfix d-none d-sm-inline-block text-uppercase">Logout</span>
      </mdb-nav-item>
    </mdb-navbar-nav>
  </mdb-navbar>
</template>

<script>
import {
  mdbNavbar,
  mdbNavbarBrand,
  mdbNavItem,
  mdbNavbarNav,
  mdbNavbarToggler,
  mdbBreadcrumb,
  mdbIcon
} from 'mdbvue'

import { mapActions } from 'vuex'

import axios from 'axios'
window.axios = axios
axios.defaults.baseURL = 'https://fpk.sempre-diritto.de/api/'

export default {
  name: 'Navbar',
  props: {
    page: {
      type: String
    }
  },
  components: {
    mdbNavbar,
    mdbNavbarBrand,
    mdbNavItem,
    mdbNavbarNav,
    mdbNavbarToggler,
    mdbBreadcrumb,
    mdbIcon
  },
  data () {
    return {
      activePage: this.page,
      toggle: false
    }
  },
  methods: {
    ...mapActions(['toggleSideNav']),
    resetDatabase: function () {
      var url = 'reset_database.php'
      axios({
        method: 'post',
        url: url,
        data: this.model
      }).then(response => {
        if (response.data.success === false) {
          alert(response.data.error)
        }
      }).catch(error => {
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    }
  }, 
  computed: {
    computedPage () {
      return {
        activePage: this.page
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.navbar .nav-item {
  line-height: 24px;
  font-size: 16px;
}
.navbar .dropdown-toggle {
  padding: 0 10px;
  font-size: 16px;
}

.navbar ul {
  padding: 5px 0;
}

.navbar .btn {
  font-weight: 300;
}

.navbar-collapse {
  order: 0 !important;
}

.navbar .notifications-nav .dropdown-menu {
  width: 23rem; }

.navbar-expand-lg .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.btn-transparent {
  background-color: transparent !important;
  color: #000;
  text-transform: none;
  box-shadow: none !important;
}

.btn-transparent:hover, .btn-transparent:active {
  box-shadow: none !important;
}

.breadcrumb {
  font-size: 20px !important;
  line-height: 22px !important;
  text-transform: uppercase;
}

</style>
