<template>
  <mdb-container class="fixed-background" fluid>
    <!-- Section: classList -->
    <section v-show="showClassList" class="class-list transparent-background pl-5 pt-4 pr-5 pb-5" style="height: calc(100vh - 62px);">
      <mdb-tabs
        :active="0"
        pills
        color="light-green"
        @activeTab="getActiveFilter"
        :links="[
          { text: 'Alle' },
          { text: 'Fisch' },
          { text: 'Fleisch' },
          { text: 'vegan/vegetarisch' },
          { text: 'Länderküche' },
          { text: 'Carlsplatz' }]"
      />
      <article class="pt-3" v-for="item in this.classes" v-bind:key="item.id">
        <mdb-row>
          <mdb-col col="8">
            <h3>
              <strong>{{ item.title }}</strong> <small>mit {{ item.cook_full_name }}</small>
            </h3>
            <p>
              {{ item.description }}
            </p>
            <p>
              {{ item.day }}, <b>{{ item.date_formatted }}</b>, {{ item.start }} - {{ item.end }} Uhr
              <br>
              Ort: {{ item.location_title }}
            </p>
            <p>
              <b>{{ item.price }} €</b> pro Person (inkl. aller Getränke)
            </p>
          </mdb-col>
          <mdb-col col="4" class="text-right mt-auto">
            <div class="d-inline-block">
              <div class="d-inline-block" v-for="ni in item.filler" v-bind:key="'A'+ ni">
                <mdb-icon class="d-inline-block ml-2" size="lg" fas icon="square" color="light-green" />
              </div>
              <div class="d-inline-block" v-for="nr in item.reserved" v-bind:key="'B'+ nr">
                <mdb-icon class="d-inline-block ml-2" size="lg" fas icon="square" color="light-green" />
              </div>
              <div class="d-inline-block" v-for="nf in item.free" v-bind:key="'C'+ nf">
                <mdb-icon class="d-inline-block ml-2" size="lg" far icon="square" color="light-green" />
              </div>
              <p>
                noch <b class="font-weight-bold">{{ item.free }}</b> von {{ item.slots + item.filler }} Plätzen frei
              </p>
              <mdb-btn v-show="item.slots - item.reserved > 0" color="light-green" @click="selectClass(item.id, item.title, item.free)">Buchen</mdb-btn>
              <mdb-btn v-show="item.slots - item.reserved <= 0" disabled outline="light-green">Ausgebucht</mdb-btn>
            </div>
          </mdb-col>
        </mdb-row>
        <hr>
      </article>
    </section>
    <!-- /Section: classList -->
    <!-- Section: bookingForm -->
    <section v-show="showBookingForm" class="booking-form transparent-background pl-5 pt-4 pr-5 pb-5" style="height: calc(100vh - 62px);">
      <article class="pt-3">
        <h1 class="text-uppercase light-green-text">Buchung</h1>
        <h2>Schön, dass Sie zu uns kommen möchten!</h2>
        <p class="mt-4">Bitte geben Sie jetzt Ihre Daten ein:</p>
        <form id="booking-form" novalidate @submit.prevent="submitBooking" @keydown.enter.prevent>
          <mdb-row>
            <mdb-col md="4" lg="2">
              <mdb-select class="mt-0" v-model="booking.title" placeholder="" label="Anrede *" required />
            </mdb-col>
            <mdb-col md="4" lg="5">
              <mdb-input class="mt-0" v-model="booking.first_name" label="Vorname *" required />
            </mdb-col>
            <mdb-col md="4" lg="5">
              <mdb-input class="mt-0" v-model="booking.last_name" label="Nachname *" required />
            </mdb-col>
          </mdb-row>
          <mdb-row>
            <mdb-col md="6" lg="6" class="mt-0">
              <mdb-input type="email" class="mt-0" v-model="booking.email" label="E-Mail *" required />
            </mdb-col>
            <mdb-col md="6" lg="6">
              <mdb-input class="mt-0" v-model="booking.phone" label="Telefon *" required />
            </mdb-col>
          </mdb-row>
          <mdb-input v-model="booking.company" label="Firma" />
          <mdb-input class="mt-0" v-model="booking.street" label="Straße & Hausnummer" />
          <mdb-input class="mt-0" v-model="booking.street_additional" label="Adresszusatz" />
          <mdb-row>
            <mdb-col md="3" lg="3">
              <mdb-input class="mt-0" v-model="booking.zip" label="Postleitzahl" />
            </mdb-col>
            <mdb-col md="9" lg="9">
              <mdb-input class="mt-0" v-model="booking.city" label="Stadt" />
            </mdb-col>
          </mdb-row>
          <h4 class="mt-4">Ich möchte im Kurs <b>"{{ booking.class_title }}"</b> folgende Anzahl an Plätzen buchen:</h4>
          <mdb-select class="mt-0 d-inline-block" v-model="booking.slots" @getValue="getSlotsValue" :visibleOptions="6" placeholder="" label="" />
          <article v-show="showSlots >= 1">
            <h6 class="mt-3 mb-4 light-green-text font-weight-bolder">1. Teilnehmer</h6>
            <mdb-row>
              <mdb-col md="4" lg="2">
                <mdb-select class="mt-0" v-model="booking.participant1.title" placeholder="" label="Anrede *" required />
              </mdb-col>
              <mdb-col md="4" lg="5">
                <mdb-input class="mt-0" v-model="booking.participant1.first_name" label="Vorname *" required />
              </mdb-col>
              <mdb-col md="4" lg="5">
                <mdb-input class="mt-0" v-model="booking.participant1.last_name" label="Nachname *" required />
              </mdb-col>
            </mdb-row>
            <!--<mdb-input class="mt-0" v-model="booking.participant1.email" label="E-Mail *" />-->
          </article>
          <article v-show="showSlots >= 2">
            <h6 class="mt-3 mb-4 light-green-text font-weight-bolder">2. Teilnehmer</h6>
            <mdb-row>
              <mdb-col md="4" lg="2">
                <mdb-select class="mt-0" v-model="booking.participant2.title" placeholder="" label="Anrede *" required />
              </mdb-col>
              <mdb-col md="4" lg="5">
                <mdb-input class="mt-0" v-model="booking.participant2.first_name" label="Vorname *" required />
              </mdb-col>
              <mdb-col md="4" lg="5">
                <mdb-input class="mt-0" v-model="booking.participant2.last_name" label="Nachname *" required />
              </mdb-col>
            </mdb-row>
            <!--<mdb-input class="mt-0" v-model="booking.participant2.email" label="E-Mail *" />-->
          </article>
          <article v-show="showSlots >= 3">
            <h6 class="mt-3 mb-4 light-green-text font-weight-bolder">3. Teilnehmer</h6>
            <mdb-row>
              <mdb-col md="4" lg="2">
                <mdb-select class="mt-0" v-model="booking.participant3.title" placeholder="" label="Anrede *" required />
              </mdb-col>
              <mdb-col md="4" lg="5">
                <mdb-input class="mt-0" v-model="booking.participant3.first_name" label="Vorname *" required />
              </mdb-col>
              <mdb-col md="4" lg="5">
                <mdb-input class="mt-0" v-model="booking.participant3.last_name" label="Nachname *" required />
              </mdb-col>
            </mdb-row>
            <!--<mdb-input class="mt-0" v-model="booking.participant3.email" label="E-Mail *" />-->
          </article>
          <article v-show="showSlots >= 4">
            <h6 class="mt-3 mb-4 light-green-text font-weight-bolder">4. Teilnehmer</h6>
            <mdb-row>
              <mdb-col md="4" lg="2">
                <mdb-select class="mt-0" v-model="booking.participant4.title" placeholder="" label="Anrede *" required />
              </mdb-col>
              <mdb-col md="4" lg="5">
                <mdb-input class="mt-0" v-model="booking.participant4.first_name" label="Vorname *" required />
              </mdb-col>
              <mdb-col md="4" lg="5">
                <mdb-input class="mt-0" v-model="booking.participant4.last_name" label="Nachname *" required />
              </mdb-col>
            </mdb-row>
            <!--<mdb-input class="mt-0" v-model="booking.participant4.email" label="E-Mail *" />-->
          </article>
          <article v-show="showSlots >= 5">
            <h6 class="mt-3 mb-4 light-green-text font-weight-bolder">5. Teilnehmer</h6>
            <mdb-row>
              <mdb-col md="4" lg="2">
                <mdb-select class="mt-0" v-model="booking.participant5.title" placeholder="" label="Anrede *" required />
              </mdb-col>
              <mdb-col md="4" lg="5">
                <mdb-input class="mt-0" v-model="booking.participant5.first_name" label="Vorname *" required />
              </mdb-col>
              <mdb-col md="4" lg="5">
                <mdb-input class="mt-0" v-model="booking.participant5.last_name" label="Nachname *" required />
              </mdb-col>
            </mdb-row>
            <!--<mdb-input class="mt-0" v-model="booking.participant5.email" label="E-Mail *" />-->
          </article>
          <article v-show="showSlots >= 6">
            <h6 class="mt-3 mb-4 light-green-text font-weight-bolder">6. Teilnehmer</h6>
            <mdb-row>
              <mdb-col md="4" lg="2">
                <mdb-select class="mt-0" v-model="booking.participant6.title" placeholder="" label="Anrede *" required />
              </mdb-col>
              <mdb-col md="4" lg="5">
                <mdb-input class="mt-0" v-model="booking.participant6.first_name" label="Vorname *" required />
              </mdb-col>
              <mdb-col md="4" lg="5">
                <mdb-input class="mt-0" v-model="booking.participant6.last_name" label="Nachname *" required />
              </mdb-col>
            </mdb-row>
            <!--<mdb-input class="mt-0" v-model="booking.participant6.email" label="E-Mail *" />-->
          </article>
          <p>Wenn Sie mehr als 6 Plätze buchen möchten, nehmen Sie bitte zunächst Kontakt mit uns auf. Entweder telefonisch unter 0211 152 09 71 oder per <a href="mailto:service@frankpetzchen.de">E-Mail</a>.</p>
          <h4 class="mt-4">Besitzen Sie einen Gutschein?</h4>
          <mdb-input class="mt-0" v-model="booking.coupon_code" label="Gutschein-Code" />
          <p>(Bitte überweisen Sie in diesem Fall nur die entsprechende Differenz!)</p>
          <p>
            <a href="https://frankpetzchen.de/agb" target="_blank">Unsere AGBs</a> | <a href="https://frankpetzchen.de/datenschutzerklaerung" target="_blank">Unsere Datenschutzerklärung</a>
          </p>
          <mdb-input type="checkbox" id="checkbox1" name="check1" v-model="booking.terms_accepted" label="Ich habe die AGB gelesen und erkenne diese an *" required />
          <p class="mt-2"><small>* Pflichtangaben</small></p>
        </form>
        <mdb-btn type="submit" form="booking-form" color="light-green" class="mt-0 first-form-button">Kurs buchen</mdb-btn>
        <mdb-btn class="mt-0" outline="light-green" @click="cancelBooking()">Abbrechen</mdb-btn>
      </article>
    </section>
    <!-- /Section: bookingForm -->
  </mdb-container>
</template>

<script>
import axios from 'axios'
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbIcon,
  mdbBtn,
  mdbInput,
  mdbSelect,
  mdbTabs
} from 'mdbvue'

window.axios = axios
axios.defaults.baseURL = 'https://fpk.sempre-diritto.de/api/'

export default {
  name: 'Classes',
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbBtn,
    mdbInput,
    mdbSelect,
    mdbTabs
  },
  data () {
    return {
      keywords: [],
      classes: [],
      showClassList: true,
      showBookingForm: false,
      showSlots: 1,
      wasValidated: false,
      booking: {
        class: 0,
        class_title: '',
        title: [
          {
            text: "Frau",
            value: "Frau",
            selected: true
          },
          {
            text: "Herr",
            value: "Herr"
          }
        ],
        first_name: '',
        last_name: '',
        email: '',
        company: '',
        street: '',
        street_additional: '',
        zip: '',
        city: '',
        phone: '',
        slots: [
          {
            text: "1 Platz",
            value: 1,
            selected: true
          },
          {
            text: "2 Plätze",
            value: 2
          },
          {
            text: "3 Plätze",
            value: 3
          },
          {
            text: "4 Plätze",
            value: 4
          },
          {
            text: "5 Plätze",
            value: 5
          },
          {
            text: "6 Plätze",
            value: 6
          }
        ],
        coupon_code: '',
        terms_accepted: false,
        participant1: {
          title: [
            {
              text: "Frau",
              value: "Frau",
              selected: true
            },
            {
              text: "Herr",
              value: "Herr"
            }
          ],
          first_name: '',
          last_name: '',
          email: ''
        },
        participant2: {
          title: [
            {
              text: "Frau",
              value: "Frau",
              selected: true
            },
            {
              text: "Herr",
              value: "Herr"
            }
          ],
          first_name: '',
          last_name: '',
          email: ''
        },
        participant3: {
          title: [
            {
              text: "Frau",
              value: "Frau",
              selected: true
            },
            {
              text: "Herr",
              value: "Herr"
            }
          ],
          first_name: '',
          last_name: '',
          email: ''
        },
        participant4: {
          title: [
            {
              text: "Frau",
              value: "Frau",
              selected: true
            },
            {
              text: "Herr",
              value: "Herr"
            }
          ],
          first_name: '',
          last_name: '',
          email: ''
        },
        participant5: {
          title: [
            {
              text: "Frau",
              value: "Frau",
              selected: true
            },
            {
              text: "Herr",
              value: "Herr"
            }
          ],
          first_name: '',
          last_name: '',
          email: ''
        },
        participant6: {
          title: [
            {
              text: "Frau",
              value: "Frau",
              selected: true
            },
            {
              text: "Herr",
              value: "Herr"
            }
          ],
          first_name: '',
          last_name: '',
          email: ''
        }
      }
      
    }
  },
  mounted: function() {
    this.loadClasses()
  },
  methods: {
    loadClasses () {
      axios.get('list_keywords.php').then(response => {
        this.keywords = response.data
      })
      axios.get('frontend_list_classes.php').then(response => {
        this.classes = response.data
      })
    },
    getActiveFilter (filter) {
      axios.get('frontend_list_classes.php?filter=' + filter).then(response => {
        this.classes = response.data
      })
    },
    selectClass (classId, classTitle, classFree) {
      this.showClassList = false
      this.showBookingForm = true
      this.booking.class = classId
      this.booking.class_title = classTitle
      this.booking.slots = Array.prototype.slice.call(this.booking.slots, 0, classFree)
    },
    getSlotsValue (slots) {
      this.showSlots = slots
    },
    validateBooking() {
      var valid = true
      if (this.booking.terms_accepted != 1) {
        valid = false;
      }
      if (this.showSlots < 1 || this.showSlots > 6) {
        valid = false;
      }
      if (this.booking.title === '' || this.booking.first_name === '' || this.booking.last_name === '' || this.booking.email === '' || this.booking.phone === '') {
        valid = false;
      }
      if (this.showSlots >= 1 && (this.booking.participant1.title === '' || this.booking.participant1.first_name === '' || this.booking.participant1.last_name === '')) {
        valid = false;
      }
      if (this.showSlots >= 2 && (this.booking.participant2.title === '' || this.booking.participant2.first_name === '' || this.booking.participant2.last_name === '')) {
        valid = false;
      }
      if (this.showSlots >= 3 && (this.booking.participant3.title === '' || this.booking.participant3.first_name === '' || this.booking.participant3.last_name === '')) {
        valid = false;
      }
      if (this.showSlots >= 4 && (this.booking.participant4.title === '' || this.booking.participant4.first_name === '' || this.booking.participant4.last_name === '')) {
        valid = false;
      }
      if (this.showSlots >= 5 && (this.booking.participant5.title === '' || this.booking.participant5.first_name === '' || this.booking.participant5.last_name === '')) {
        valid = false;
      }
      if (this.showSlots >= 6 && (this.booking.participant6.title === '' || this.booking.participant6.first_name === '' || this.booking.participant6.last_name === '')) {
        valid = false;
      }
      return valid
    },
    submitBooking(event) {
      this.wasValidated = true
      event.target.classList.add('was-validated')
      if (this.validateBooking() === true) {
        var url = 'add_booking.php'
        axios({
          method: 'post',
          url: url,
          data: this.booking
        }).then(response => {
          if (response.data.success === false) {
            alert(response.data.error)
          } else {
            this.cancelBooking()
          }
        }).catch(error => {
          alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
        })
      }
    },
    cancelBooking () {
      this.wasValidated = false
      document.querySelector('#booking-form').classList.remove('was-validated')
      axios.get('frontend_list_classes.php').then(response => {
        this.classes = response.data
      })
      this.showClassList = true
      this.showBookingForm = false
      this.showSlots = 1
      this.booking.class = 0
      this.booking.class_title = ''
      this.booking.title = [
        {
          text: "Frau",
          value: "Frau",
          selected: true
        },
        {
          text: "Herr",
          value: "Herr"
        }
      ]
      this.booking.first_name = ''
      this.booking.last_name = ''
      this.booking.email = ''
      this.booking.company = ''
      this.booking.street = ''
      this.booking.street_additional = ''
      this.booking.zip = ''
      this.booking.city = ''
      this.booking.phone = ''
      this.booking.slots = [
        {
          text: "1 Platz",
          value: 1,
          selected: true
        },
        {
          text: "2 Plätze",
          value: 2
        },
        {
          text: "3 Plätze",
          value: 3
        },
        {
          text: "4 Plätze",
          value: 4
        },
        {
          text: "5 Plätze",
          value: 5
        },
        {
          text: "6 Plätze",
          value: 6
        }
      ]
      this.booking.coupon_code = ''
      this.booking.terms_accepted = false
      this.booking.participant1 = {
        title: [
          {
            text: "Frau",
            value: "Frau",
            selected: true
          },
          {
            text: "Herr",
            value: "Herr"
          }
        ],
        first_name: '',
        last_name: '',
        email: ''
      },
      this.booking.participant2 = {
        title: [
          {
            text: "Frau",
            value: "Frau",
            selected: true
          },
          {
            text: "Herr",
            value: "Herr"
          }
        ],
        first_name: '',
        last_name: '',
        email: ''
      },
      this.booking.participant3 = {
        title: [
          {
            text: "Frau",
            value: "Frau",
            selected: true
          },
          {
            text: "Herr",
            value: "Herr"
          }
        ],
        first_name: '',
        last_name: '',
        email: ''
      },
      this.booking.participant4 = {
        title: [
          {
            text: "Frau",
            value: "Frau",
            selected: true
          },
          {
            text: "Herr",
            value: "Herr"
          }
        ],
        first_name: '',
        last_name: '',
        email: ''
      },
      this.booking.participant5 = {
        title: [
          {
            text: "Frau",
            value: "Frau",
            selected: true
          },
          {
            text: "Herr",
            value: "Herr"
          }
        ],
        first_name: '',
        last_name: '',
        email: ''
      },
      this.booking.participant6 = {
        title: [
          {
            text: "Frau",
            value: "Frau",
            selected: true
          },
          {
            text: "Herr",
            value: "Herr"
          }
        ],
        first_name: '',
        last_name: '',
        email: ''
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .fixed-background {
    background-image: url("/bg_kurse.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: calc(100vh - 61px) !important;
  }

  .transparent-background {
    background-color: rgba(255, 255, 255, 0.95);
    overflow: scroll;
    overflow-x: hidden;
  }

  .class-list {
    margin-left: auto;
    margin-right: auto;
    max-width: 1024px;
  }

  .class-list .nav-item:first-of-type {
    padding-left: 0px;
  }

  .class-list h3 {
    font-size: 19px !important;
    font-weight: 500 !important;
  }

  .class-list h3 small {
    font-weight: 300;
  }

  .booking-form {
    margin-left: auto;
    margin-right: auto;
    max-width: 1024px;
  }

  .booking-form .select-dropdown, .booking-form .select-wrapper {
    margin-top: 0px !important;
  }

  .booking-form .form-check {
    padding-left: 0px !important;
  }

  .booking-form .first-form-button {
    margin-left: 0px !important;
  }
</style>
