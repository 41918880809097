<template>
  <mdb-container fluid>
    <!-- Section: Grid -->
    <section style="height: calc(100vh - 62px);">
      <ejs-grid
        ref="grid"
        locale="de"
        height="100%"
        :dataSource="dataSource"
        :dataBound="dataBound"
        :queryCellInfo="queryClassCellInfo"
        :toolbar="toolbar"
        :toolbarClick="clickToolbar"
        :allowGrouping="true"
        :groupSettings="groupSettings"
        :allowSorting="true"
        :sortSettings="sortSettings"
        :rowSelected="selectRow"
        :rowDeselected="deselectRow"
      >
        <e-columns>
          <e-column
            field="class_date"
            headerText="Kursdatum"
            textAlign="Right"
            format="dd.MM.yyyy"
            type="date"
            :customAttributes="dateColumnAttributes"
          ></e-column>
          <e-column
            field="created"
            headerText="Eingang"
            textAlign="Right"
            format="dd.MM.yyyy HH:mm"
            type="date"
          ></e-column>
          <e-column
            field="payment_id"
            headerText="Buchungs-Nr."
            textAlign="Right"
          ></e-column>
          <e-column
            field="class_title"
            headerText="Kurs"
            textAlign="Left"
            :customAttributes="titleColumnAttributes"
          ></e-column>
          <e-column
            field="payment_client"
            headerText="Kunde"
            textAlign="Left"
          ></e-column>
          <e-column
            field="id"
            headerText="Teilnehmer ID"
            textAlign="Right"
            :isPrimaryKey="true"
          ></e-column>
          <e-column
            field="participant_full_name"
            headerText="Teilnehmer"
            textAlign="Left"
            :customAttributes="titleColumnAttributes"
          ></e-column>
          <e-column
            field="participant_company"
            headerText="Firma"
            textAlign="Left"
            :visible="false"
          ></e-column>
          <e-column
            field="participant_address"
            headerText="Adresse"
            textAlign="Left"
            :visible="false"
          ></e-column>
          <e-column
            field="participant_phone"
            headerText="Telefon"
            textAlign="Left"
            :visible="false"
          ></e-column>
          <e-column
            field="participant_email"
            headerText="E-Mail"
            textAlign="Left"
            :visible="false"
          ></e-column>
          <e-column
            field="payment_status"
            headerText="Teilnehmerstatus"
            textAlign="Left"
            width="200"
          ></e-column>
          <e-column
            field="status"
            headerText="Buchungsstatus"
            textAlign="Left"
            width="200"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </section>
    <!-- /Section: Grid -->
    <!-- Modal: Booking -->
    <mdb-modal
      side
      position="right"
      direction="right"
      full-height
      size="lg"
      scrollable
      :show="showModal"
      @close="closeModal"
    >
      <mdb-modal-header>
        <mdb-modal-title>Buchung {{ model.payment_id }} | Teilnehmer {{ model.id }} | {{ model.participant_full_name }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <form id="modal-form" novalidate @submit.prevent="saveModal">
          <mdb-tab pills header justify color="light-green" class="mx-0 mb-0">
            <mdb-tab-item icon="user" :active="pillsActive==0" @click.native.prevent="pillsActive=0">Teilnehmer</mdb-tab-item>
            <mdb-tab-item icon="list-ul" :active="pillsActive==1" @click.native.prevent="pillsActive=1">Buchung</mdb-tab-item>
            <mdb-tab-item icon="file-invoice" :active="pillsActive==2" @click.native.prevent="pillsActive=2">Rechnungswesen</mdb-tab-item>
          </mdb-tab>
          <mdb-tab-content>
            <mdb-tab-pane v-if="pillsActive==0">
              <mdb-row>
                <mdb-col md="6" class="mb-2">
                  <legend><mdb-icon icon="user" class="light-green-text" /> Teilnehmer</legend>
                  <input type="hidden" v-model="model.id">
                  <input type="hidden" v-model="model.parent">
                  <mdb-select
                    label="Anrede"
                    v-model="model.participant_title"
                    validation
                  />
                  <mdb-input
                    label="Vorname"
                    type="text"
                    v-model="model.participant_first_name"
                    required
                  />
                  <mdb-input
                    label="Nachname"
                    type="text"
                    v-model="model.participant_last_name"
                    required
                  />
                  <legend><mdb-icon icon="edit" fas class="light-green-text" /> Anmerkung Teilnehmer</legend>
                  <mdb-input
                    type="textarea"
                    v-model="model.participant_memo"
                    :rows="5"
                  />
                </mdb-col>
                <mdb-col md="6" class="mb-2">
                  <legend><mdb-icon icon="info-circle" fas class="light-green-text" /> Status</legend>
                  <mdb-tbl>
                    <mdb-tbl-body>
                      <tr>
                        <td>Zahlungsdatum</td>
                        <td>{{ model.payment_date }}</td>
                      </tr>
                      <tr>
                        <td>Zahlungsart</td>
                        <td>{{ model.payment_type }}</td>
                      </tr>
                      <tr>
                        <td>Einzelpreis</td>
                        <td>{{ model.payment_participant }} &euro;</td>
                      </tr>
                      <tr>
                        <td>Summe gezahlt</td>
                        <td>{{ model.payment_participant_paid }} &euro;</td>
                      </tr>
                      <tr>
                        <td>Summe offen</td>
                        <td>{{ model.payment_participant_due }} &euro;</td>
                      </tr>
                      <tr>
                        <td>Gutschein-Code</td>
                        <td>{{ model.coupon_code }}</td>
                      </tr>
                      <tr>
                        <td>Gutschein-Wert verrechnet</td>
                        <td>{{ model.coupon_value_spent }} <span v-show="model.coupon_value_spent !== '-'">&euro;</span></td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>
                          <mdb-input type="radio" id="payment-status-1" name="Zahlungsstatus" radioValue="offen" v-model="model.payment_status" label="offen" checked disabled />
                          <mdb-input type="radio" id="payment-status-2" name="Zahlungsstatus" radioValue="bezahlt" v-model="model.payment_status" label="bezahlt" disabled />
                          <mdb-input type="radio" id="payment-status-3" name="Zahlungsstatus" radioValue="teilbezahlt" v-model="model.payment_status" label="teilbezahlt" disabled />
                          <!--<mdb-input type="radio" id="payment-status-4" name="Zahlungsstatus" radioValue="überfällig" v-model="model.payment_status" label="überfällig" disabled />-->
                          <!--<mdb-input type="radio" id="payment-status-5" name="Zahlungsstatus" radioValue="angemahnt" v-model="model.payment_status" label="angemahnt" disabled />-->
                          <mdb-input type="radio" id="payment-status-6" name="Zahlungsstatus" radioValue="storniert" v-model="model.payment_status" label="storniert" disabled />
                        </td>
                      </tr>
                    </mdb-tbl-body>
                  </mdb-tbl>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col md="12" class="mb-2" v-if="modalEdit">
                  <legend><mdb-icon icon="angle-double-right" fas class="light-green-text" /> Aktionen</legend>
                  <mdb-btn color="light-green" @click.native="showPayment = true"><mdb-icon icon="credit-card" fas class="mr-1"/> Einzelzahlung</mdb-btn>
                  <mdb-btn color="danger" @click.native="showCancel = true" v-show="model.payment_status !== 'storniert'"><mdb-icon icon="ban" fas class="mr-1"/> Teilnehmer stornieren</mdb-btn>
                  <mdb-btn color="yellow" @click.native="showRestore = true" v-show="model.payment_status === 'storniert'"><mdb-icon icon="redo" fas class="mr-1"/> Stornierung widerrufen</mdb-btn>
                </mdb-col>
              </mdb-row>
            </mdb-tab-pane>
            <mdb-tab-pane v-if="pillsActive==1">
              <mdb-row>
                <mdb-col md="6" class="mb-2">
                  <legend><mdb-icon icon="list-ul" class="light-green-text" /> Buchung</legend>
                  <mdb-tbl>
                    <mdb-tbl-body>
                      <tr>
                        <td>Buchungs-Nr.</td>
                        <td>{{ model.payment_id }}</td>
                      </tr>
                      <tr>
                        <td>Teilnehmer-Nr.</td>
                        <td>{{ model.id }}</td>
                      </tr>
                      <tr>
                        <td>Eingangsdatum</td>
                        <td>{{ model.created }}</td>
                      </tr>
                      <tr>
                        <td>Kurs</td>
                        <td>{{ model.class_title }}</td>
                      </tr>
                      <tr>
                        <td>Kursauslastung</td>
                        <td>{{ model.capacity }}</td>
                      </tr>
                      <tr>
                        <td>Gebuchte Plätze</td>
                        <td>{{ model.slots }}</td>
                      </tr>
                      <tr>
                        <td>Anmerkung des Kunden</td>
                        <td>{{ model.memo }}</td>
                      </tr>
                    </mdb-tbl-body>
                  </mdb-tbl>
                  <legend><mdb-icon icon="edit" fas class="light-green-text" /> Anmerkung Buchung</legend>
                  <mdb-input
                    type="textarea"
                    v-model="model.booking_memo"
                    :rows="5"
                  />
                </mdb-col>
                <mdb-col md="6" class="mb-2">
                  <legend><mdb-icon icon="user-alt" fas class="light-green-text" /> Kunde</legend>
                  <mdb-select
                    label="Anrede"
                    v-model="model.parent_title"
                    validation
                  />
                  <mdb-input
                    label="Vorname"
                    type="text"
                    v-model="model.parent_first_name"
                    required
                  />
                  <mdb-input
                    label="Nachname"
                    type="text"
                    v-model="model.parent_last_name"
                    required
                  />
                  <mdb-input
                    label="Firma"
                    type="text"
                    v-model="model.participant_company"
                  />
                  <mdb-input
                    label="Straße"
                    type="text"
                    v-model="model.participant_street"
                    required
                  />
                  <mdb-input
                    label="Adresszusatz"
                    type="text"
                    v-model="model.participant_street_additional"
                  />
                  <mdb-input
                    label="PLZ"
                    type="text"
                    v-model="model.participant_zip"
                    required
                  />
                  <mdb-input
                    label="Stadt"
                    type="text"
                    v-model="model.participant_city"
                    required
                  />
                  <mdb-input
                    label="Telefon"
                    type="text"
                    v-model="model.participant_phone"
                  />
                  <mdb-input
                    label="E-Mail"
                    type="text"
                    v-model="model.participant_email"
                    required
                  />
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col md="12" class="mb-2">
                  <legend><mdb-icon icon="angle-double-right" fas class="light-green-text" /> Aktionen</legend>
                  <mdb-btn color="light-green" @click.native="showTransfer = true"><mdb-icon icon="sign-out-alt" fas class="mr-1"/> Umbuchen</mdb-btn>
                  <!--<mdb-btn color="light-green" @click.native="printParticipants"><mdb-icon icon="print" fas class="mr-1"/> Teilnehmerliste erzeugen</mdb-btn>-->
                </mdb-col>
              </mdb-row>
            </mdb-tab-pane>
            <mdb-tab-pane v-if="pillsActive==2">
              <mdb-row>
                <mdb-col md="6" class="mb-2">
                  <legend><mdb-icon icon="file-invoice" fas class="light-green-text" /> Rechnungsdaten</legend>
                  <mdb-tbl>
                    <mdb-tbl-body>
                      <tr>
                        <td>Zahlungsdatum</td>
                        <td>{{ model.parent_payment_date }}</td>
                      </tr>
                      <tr>
                        <td>Zahlungsart</td>
                        <td>{{ model.parent_payment_type }}</td>
                      </tr>
                      <tr>
                        <td>Summe gesamt</td>
                        <td>{{ model.payment_total }} &euro;</td>
                      </tr>
                      <tr>
                        <td>Summe gezahlt</td>
                        <td>{{ model.payment_total_paid }} &euro;</td>
                      </tr>
                      <tr>
                        <td>Summe offen</td>
                        <td>{{ model.payment_total_due }} &euro;</td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>
                          <mdb-input type="radio" id="booking-status-1" name="Buchungsstatus" radioValue="offen" v-model="model.status" label="offen" checked disabled />
                          <mdb-input type="radio" id="booking-status-2" name="Buchungsstatus" radioValue="bezahlt" v-model="model.status" label="bezahlt" disabled />
                          <mdb-input type="radio" id="booking-status-3" name="Buchungsstatus" radioValue="teilbezahlt" v-model="model.status" label="teilbezahlt" disabled />
                          <!--<mdb-input type="radio" id="booking-status-4" name="Buchungsstatus" radioValue="überfällig" v-model="model.status" label="überfällig" disabled />-->
                          <mdb-input type="radio" id="booking-status-5" name="Buchungsstatus" radioValue="angemahnt" v-model="model.status" label="angemahnt" disabled />
                          <mdb-input type="radio" id="booking-status-6" name="Buchungsstatus" radioValue="storniert" v-model="model.status" label="storniert" disabled />
                        </td>
                      </tr>
                    </mdb-tbl-body>
                  </mdb-tbl>
                  <mdb-input
                    label="Zahlungsziel"
                    type="text"
                    v-model="model.payment_target"
                    required
                  />
                </mdb-col>
                <mdb-col md="6" class="mb-2">
                  <legend><mdb-icon icon="tag" fas class="light-green-text" /> Gutschein</legend>
                  <mdb-tbl>
                    <mdb-tbl-body>
                      <tr>
                        <td>Hinterlegter Gutschein-Code</td>
                        <td>{{ model.parent_deposited_coupon_code }}</td>
                      </tr>
                      <tr>
                        <td>Verwendeter Gutschein-Code</td>
                        <td>{{ model.parent_coupon_code }}</td>
                      </tr>
                      <tr>
                        <td>Gutschein-Wert verrechnet</td>
                        <td>{{ model.parent_coupon_value_spent }} <span v-show="model.parent_coupon_value_spent !== '-'">&euro;</span></td>
                      </tr>
                    </mdb-tbl-body>
                  </mdb-tbl>
                  <legend><mdb-icon icon="edit" fas class="light-green-text" /> Anmerkung Rechnungswesen</legend>
                  <mdb-input
                    type="textarea"
                    v-model="model.payment_memo"
                    :rows="5"
                  />
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col md="12" class="mb-2">
                  <legend><mdb-icon icon="angle-double-right" fas class="light-green-text" /> Aktionen</legend>
                  <!--<mdb-btn color="light-green"><mdb-icon icon="gift" fas class="mr-1"/> Gutschrift erzeugen</mdb-btn>-->
                  <mdb-btn color="light-green" @click.native="showCompletePayment = true"><mdb-icon icon="credit-card" fas class="mr-1"/> Gesamtzahlung</mdb-btn>
                  <mdb-btn color="light-green" @click.native="showInvoice = true"><mdb-icon icon="file-invoice" fas class="mr-1"/> Rechnungskopie</mdb-btn>
                  <mdb-btn color="light-green" @click.native="showReceipt = true"><mdb-icon icon="receipt" fas class="mr-1"/> Zahlungsbestätigung</mdb-btn>
                  <mdb-btn color="danger" @click.native="showReminder = true"><mdb-icon icon="exclamation-triangle" fas class="mr-1"/> Mahnung</mdb-btn>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col md="12" class="mt-3">
                  <legend><mdb-icon icon="history" fas class="light-green-text" /> Verlauf</legend>
                  <div v-html="model.payment_log" class="history"></div>
                </mdb-col>
              </mdb-row>
            </mdb-tab-pane>
          </mdb-tab-content>
        </form>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="light-green" @click.native="previousRecord"><mdb-icon icon="caret-left" class="mr-1"/></mdb-btn>
        <mdb-btn outline="light-green" @click.native="nextRecord"><mdb-icon icon="caret-right" class="mr-1"/></mdb-btn>
        <mdb-btn outline="light-green" @click.native="closeModal">Schließen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="light-green" v-if="modalEdit">Speichern</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Edit -->
    <!-- Modal: Payment -->
    <mdb-modal size="lg" :show="showPayment" @close="showPayment = false" danger>
      <mdb-modal-header color="light-green">
        <p class="heading"><strong>Einzelzahlung hinzufügen/entfernen</strong></p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="credit-card" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Bitte geben Sie den Betrag an, der hinzugefügt oder abgezogen werden soll:</strong>
            </p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-text>
                  <form id="payment-form" novalidate @submit.prevent="changePayment" @keydown.enter.prevent>
                    <mdb-date-picker
                      label="Zahlungsdatum"
                      v-model="payment.date"
                      :option="datePickerOptions"
                      :isValid="isDateValid"
                      @getValue="handleDateChange"
                      disableClear
                    />
                    <mdb-select
                      label="Zahlungsart"
                      v-model="payment.method"
                      validation
                    />
                    <mdb-input
                      label="Zu verrechnende Summe inkl. Gutscheinwert (€)"
                      type="text"
                      v-model="payment.sum"
                      required
                    />
                    <mdb-input type="text" label="Gutschein-Code (optional)" v-model="payment.coupon_code" />
                    <mdb-input type="radio" id="payment-type-1" name="Zahlungsstatus" radioValue="hinzufügen" v-model="payment.type" label="Zahlung hinzufügen" checked />
                    <mdb-input type="radio" id="payment-type-2" name="Zahlungsstatus" radioValue="entfernen" v-model="payment.type" label="Zahlung entfernen" />
                    <hr class="mt-4">
                    <mdb-input
                      label="Anmerkung Rechnungswesen"
                      type="textarea"
                      v-model="payment.payment_memo"
                      :rows="5"
                    />
                  </form>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="light-green" @click="showPayment = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="payment-form" color="light-green">Ändern</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Payment -->
    <!-- Modal: Complete Payment -->
    <mdb-modal size="lg" :show="showCompletePayment" @close="showCompletePayment = false" danger>
      <mdb-modal-header color="light-green">
        <p class="heading"><strong>Gesamtzahlung hinzufügen/entfernen</strong></p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="credit-card" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Bitte geben Sie an, wie der Betrag hinzugefügt oder abgezogen werden soll:</strong>
            </p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-text>
                  <form id="payment-form" novalidate @submit.prevent="changeCompletePayment" @keydown.enter.prevent>
                    <mdb-date-picker
                      label="Zahlungsdatum"
                      v-model="payment.date"
                      :option="datePickerOptions"
                      :isValid="isDateValid"
                      @getValue="handleDateChange"
                      disableClear
                    />
                    <mdb-select
                      label="Zahlungsart"
                      v-model="payment.complete_method"
                      validation
                    />
                    <mdb-input
                      label="Summe (€)"
                      type="text"
                      v-model="payment.sum"
                      required
                      disabled
                    />
                    <mdb-input v-if="model.parent_deposited_coupon_code === '-' || model.parent_deposited_coupon_code === ''" type="text" label="Gutschein-Code (optional)" v-model="payment.coupon_code" />
                    <mdb-input v-else type="text" label="Gutschein-Code (hinterlegt)" v-model="payment.coupon_code" disabled />
                    <mdb-input type="radio" id="payment-type-1" name="Zahlungsstatus" radioValue="hinzufügen" v-model="payment.type" label="Zahlung hinzufügen" checked />
                    <mdb-input type="radio" id="payment-type-2" name="Zahlungsstatus" radioValue="entfernen" v-model="payment.type" label="Zahlung entfernen" />
                    <hr class="mt-4">
                    <mdb-input
                      label="Anmerkung Rechnungswesen"
                      type="textarea"
                      v-model="payment.payment_memo"
                      :rows="5"
                    />
                  </form>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="light-green" @click="showCompletePayment = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="payment-form" color="light-green">Ändern</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Complete Payment -->
    <!-- Modal: Reminder -->
    <mdb-modal size="lg" :show="showReminder" @close="showReminder = false" danger>
      <mdb-modal-header>
        <p class="heading"><strong>Mahnung versenden</strong></p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="exclamation-triangle" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="d-flex align-items-center h-100 mb-0">
              <strong>Möchten Sie dem Kunden eine Mahnung per E-Mail senden?</strong>
            </p>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="danger" @click="showReminder = false">Abbrechen</mdb-btn>
        <mdb-btn color="danger" @click="sendReminder">Senden</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Reminder -->
    <!-- Modal: Invoice -->
    <mdb-modal size="lg" :show="showInvoice" @close="showInvoice = false" danger>
      <mdb-modal-header color="light-green">
        <p class="heading"><strong>Rechnungskopie versenden</strong></p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="file-invoice" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="d-flex align-items-center h-100 mb-0">
              <strong>Möchten Sie dem Kunden eine Rechnungskopie per E-Mail senden?</strong>
            </p>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="light-green" @click="showInvoice = false">Abbrechen</mdb-btn>
        <mdb-btn color="light-green" @click="sendInvoice">Senden</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Invoice -->
    <!-- Modal: Receipt -->
    <mdb-modal size="lg" :show="showReceipt" @close="showReceipt = false" danger>
      <mdb-modal-header color="light-green">
        <p class="heading"><strong>Zahlungsbestätigung versenden</strong></p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="receipt" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="d-flex align-items-center h-100 mb-0">
              <strong>Möchten Sie dem Kunden eine Zahlungsbestätigung per E-Mail senden?</strong>
            </p>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="light-green" @click="showReceipt = false">Abbrechen</mdb-btn>
        <mdb-btn color="light-green" @click="sendReceipt">Senden</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Receipt -->
    <!-- Modal: Transfer -->
    <mdb-modal size="lg" :show="showTransfer" @close="showTransfer = false" danger>
      <mdb-modal-header color="light-green">
        <p class="heading"><strong>Umbuchung</strong></p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="sign-out-alt" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Bitte wählen Sie den Kurs aus, auf den umgebucht werden soll:</strong>
            </p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-text>
                  <form id="transfer-form" novalidate @submit.prevent="transferBooking" @keydown.enter.prevent>
                    <mdb-select
                      label="Kurs"
                      v-model="transfer.classes"
                      ref="transfer"
                      validation
                      search
                      @getValue="getTransferValue"
                    />
                  </form>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="light-green" @click="showTransfer = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="transfer-form" color="light-green">Umbuchen</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Transfer -->
    <!-- Modal: Cancel -->
    <mdb-modal size="lg" :show="showCancel" @close="showCancel = false" danger>
      <mdb-modal-header color="danger">
        <p class="heading"><strong>Stornierung</strong></p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="ban" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Soll der gewählte Teilnehmer wirklich storniert werden?</strong>
            </p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-text>
                  <form id="cancel-form" novalidate @submit.prevent="cancelBooking" @keydown.enter.prevent>
                    Kurs: {{ model.class_title }}<br>
                    Teilnehmer: {{ model.participant_full_name }}
                    <mdb-select @getValue="getCancelType" v-model="cancelOptions" label="Benachrichtigung des Teilnehmers wählen" />
                  </form>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="red" @click="showCancel = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="cancel-form" color="danger">Stornieren</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Cancel -->
    <!-- Modal: Restore -->
    <mdb-modal size="lg" :show="showRestore" @close="showRestore = false" danger>
      <mdb-modal-header color="warning-color">
        <p class="heading"><strong>Stornierung widerrufen</strong></p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="redo" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Soll die Stornierung des gewählten Teilnehmers wirklich rückgängig gemacht werden?</strong>
            </p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-text>
                  <form id="restore-form" novalidate @submit.prevent="restoreBooking" @keydown.enter.prevent>
                    Kurs: {{ model.class_title }}<br>
                    Teilnehmer: {{ model.participant_full_name }}
                  </form>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="yellow" @click="showRestore = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="restore-form" color="yellow">Widerrufen</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Restore -->
  </mdb-container>
</template>

<script>
import Vue from 'vue'
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json'
import * as cagregorian from 'cldr-data/main/de/ca-gregorian.json'
import * as numbers from 'cldr-data/main/de/numbers.json'
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json'
import * as weekData from 'cldr-data/supplemental/weekData.json'
import * as currencies from 'cldr-data/main/de/currencies.json'
// eslint-disable-next-line no-unused-vars
import { L10n, loadCldr, setCulture, setCurrencyCode, createElement } from '@syncfusion/ej2-base'
import { GridPlugin, Edit, Toolbar, Resize, Search, Sort, Group } from '@syncfusion/ej2-vue-grids'
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data'
import axios from 'axios'
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbIcon,
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbInput,
  mdbSelect,
  mdbTbl,
  mdbTblBody,
  mdbTab,
  mdbTabItem,
  mdbTabContent,
  mdbTabPane,
  mdbCard,
  mdbCardBody,
  mdbCardText,
  mdbDatePicker
} from 'mdbvue'

L10n.load({
  'de': {
    'grid': {
      'EmptyRecord': 'Keine Einträge gefunden',
      'GroupDropArea': 'Spalte hier ablegen um zu gruppieren',
      'UnGroup': 'Klicken Sie hier, um die Gruppierung aufheben',
      'Item': 'Eintrag',
      'Items': 'Einträge',
      'Search': 'Suche',
      'FilterButton': 'Filtern',
      'ClearButton': 'Löschen',
      'SelectAll': 'Alle',
      'Blanks': 'Leer',
      'Add': 'Hinzufügen',
      'Edit': 'Bearbeiten',
      'Delete': 'Löschen',
      'Enter the value': 'Suchbegriff eingeben'
    },
    'pager': {
      'currentPageInfo': '{0} von {1} Seiten',
      'totalItemsInfo': '({0} Einträge)',
      'firstPageTooltip': 'Zur ersten Seite',
      'lastPageTooltip': 'Zur letzten Seite',
      'nextPageTooltip': 'Zur nächsten Seite',
      'previousPageTooltip': 'Zurück zur letzten Seit',
      'nextPagerTooltip': 'Zum nächsten Pager',
      'previousPagerTooltip': 'Zum vorherigen Pager'
    }
  }
})
loadCldr(numberingSystems, cagregorian, numbers, timeZoneNames, weekData, currencies)
setCulture('de')
setCurrencyCode('EUR')

window.axios = axios
axios.defaults.baseURL = 'https://fpk.sempre-diritto.de/api/'

Vue.use(GridPlugin)

function clearSelection () {
  if (window.getSelection) {
    window.getSelection().removeAllRanges()
  } else if (document.selection) {
    document.selection.empty()
  }
}

export default {
  name: 'Bookings',
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
    mdbSelect,
    mdbTbl,
    mdbTblBody,
    mdbTab,
    mdbTabItem,
    mdbTabContent,
    mdbTabPane,
    mdbCard,
    mdbCardBody,
    mdbCardText,
    mdbDatePicker
  },
  data () {
    return {
      model: {
        id: 'new',
        parent: 0,
        created: '',
        terms_accepted: '',
        cancelled: '',
        class: '',
        class_title: '',
        slots: 1,
        capacity: '',
        memo: '',
        status: 'offen',
        coupon: '',
        coupon_code: '',
        coupon_value_total: '0,00',
        coupon_value_spent: '0,00',
        payment_id: '',
        payment_date: '',
        payment_type: 'Überweisung',
        payment_target: '14 Tage',
        payment_total: '0,00',
        payment_total_paid: '0,00',
        payment_total_due: '0,00',
        payment_participant: '0,00',
        payment_participant_paid: '0,00',
        payment_participant_due: '0,00',
        payment_memo: '',
        payment_status: 'offen',
        payment_log: '',
        credit_date: '',
        credit_value: '0,00',
        participant_title: [
          { text: 'Herr', value: 'Herr', selected: false },
          { text: 'Frau', value: 'Frau', selected: false }
        ],
        participant_first_name: '',
        participant_last_name: '',
        participant_full_name: '',
        participant_fullest_name: '',
        participant_company: '',
        participant_street: '',
        participant_street_additional: '',
        participant_zip: '',
        participant_city: '',
        participant_address: '',
        participant_phone: '',
        participant_email: '',
        participant_memo: '',
        parent_title: [
          { text: 'Herr', value: 'Herr', selected: false },
          { text: 'Frau', value: 'Frau', selected: false }
        ],
        parent_first_name: '',
        parent_last_name: '',
        parent_payment_date: '',
        parent_payment_type: '',
        parent_coupon_code: '',
        parent_coupon_value_total: '',
        parent_coupon_value_spent: ''
      },
      payment: {
        id: '',
        date: '',
        method: [
          {
            text: "Überweisung",
            value: "Überweisung",
            selected: true
          },
          {
            text: "Bar",
            value: "Bar"
          },
          {
            text: "Kulanz",
            value: "Kulanz"
          },
          {
            text: "Teil einer Gesamtzahlung",
            value: "Teil einer Gesamtzahlung"
          }
        ],
        complete_method: [
          {
            text: "Überweisung",
            value: "Überweisung",
            selected: true
          },
          {
            text: "Bar",
            value: "Bar"
          },
          {
            text: "Kulanz",
            value: "Kulanz"
          }
        ],
        sum: '0,00',
        type: 'hinzufügen',
        coupon_code: '',
        payment_memo: ''
      },
      cancelOptions: [{
          text: "Keine Benachrichtigung",
          value: "keine",
          selected: true
        },
        {
          text: "E-Mail: Stornierung auf Wunsch des Kunden",
          value: "Option 2"
        },
        {
          text: "E-Mail: Stornierung wegen ausbleibender Zahlung",
          value: "Option 3"
        }
      ],
      datePickerOptions: {
        format: 'DD.MM.YYYY',
        week: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
        month: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        placeholder: '',
        buttons: {
          ok: 'Speichern',
          clear: 'Zurücksetzen',
          today: 'Heute'
        }
      },
      groupSettings: { 
        captionTemplate: "<div>${format(data)}</div>",
        columns: ['payment_id']
      },
      transfer: {
        id: '',
        parent: '',
        newClass: '',
        classes: []
      },
      cancelType: 0,
      showModal: false,
      modalEdit: false,
      showPayment: false,
      showMail: false,
      showCancel: false,
      showRestore: false,
      showCompletePayment: false,
      isDateValid: false,
      showReminder: false,
      showInvoice: false,
      showReceipt: false,
      showTransfer: false,
      pillsActive: 0,
      selectedGrid: '',
      dataSource: new DataManager({
        url: 'https://fpk.sempre-diritto.de/api/list_bookings.php',
        adaptor: new UrlAdaptor,
        crossDomain: true
      }),
      toolbar: [
        'Search'
      ],
      formatOptions: {
        type: 'date',
        format: 'dd.MM.yyyy'
      },
      sortSettings: {
        columns: [{
          field: 'created',
          direction: 'Descending'
        }]
      },
      sortSettingsBooking: {
        columns: [{
          field: 'booking_created',
          direction: 'Descending'
        }]
      },
      dateColumnAttributes : {
        class: 'date-column'
      },
      titleColumnAttributes : {
        class: 'title-column'
      },
      capacityColumnAttributes : {
        class: 'capacity-column'
      }
    }
  },
  watch: {
    showPayment: function () {
      if (this.showPayment == false) {
        this.payment.date = ''
        this.payment.sum = '0,00'
        this.payment.type = 'hinzufügen'
        this.payment.coupon_code = ''
        this.payment.payment_memo = ''
      }
      if (this.showPayment == true) {
        this.payment.id = this.model.id
      }
    },
    showCompletePayment: function () {
      if (this.showCompletePayment == true) {
        this.payment.id = this.model.id
        this.payment.sum = this.model.payment_total
        if (this.model.parent_deposited_coupon_code !== '-' && this.model.parent_deposited_coupon_code !== '') {
          this.payment.coupon_code = this.model.parent_deposited_coupon_code
        }
      } else {
        this.payment.date = ''
        this.payment.sum = '0,00'
        this.payment.type = 'hinzufügen'
        this.payment.coupon_code = ''
        this.payment.payment_memo = ''
      }
    },
    showCancel: function () {
      if (this.showCompletePayment == false) {
        this.cancelType = 0
        this.cancelOptions = [{
            text: "Keine Benachrichtigung",
            value: "1",
            selected: true
          },
          {
            text: "E-Mail: Stornierung auf Wunsch des Kunden",
            value: "2"
          },
          {
            text: "E-Mail: Stornierung wegen ausbleibender Zahlung",
            value: "3"
          }
        ]
      }
    },
    showTransfer: function () {
      if (this.showTransfer == false) {
        this.transfer.id = ''
        this.transfer.parent = ''
        this.transfer.newClass = ''
        this.transfer.classes = []
      }
      if (this.showTransfer == true) {
        this.transfer.id = this.model.id
        this.transfer.parent = this.model.parent
        axios.get('list_classes.php').then(response => {
          var self = this
          response.data.result.forEach(function(classitem) {
            var row = { text: classitem.date_formatted + ' - ' + classitem.title, value: classitem.id, selected: false }
            self.transfer.classes.push(row);
          })
        })
      }
    }
  },
  mounted: function() {
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('keyup', () => {
      this.$refs.grid.search((event.target).value)
    })
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('click', () => {
      this.$refs.grid.ej2Instances.searchSettings.key = '';
    })
    this.resetModel()
  },
  methods: {
    dataBound: function () {
      this.$refs.grid.autoFitColumns(['class_date', 'created', 'payment_id', 'id', 'payment_status', 'status'])
    },
    actionComplete: function (e) {
      if (e.requestType === 'grouping' || e.requestType === 'searchingss') {
        this.$refs.grid.ej2Instances.groupModule.collapseAll()
        //console.log(this.groupSettings.columns)
      }
    },
    queryClassCellInfo: function (args) {
      if (args.column.field === 'status') {
        if (args.data['status'] === 'bezahlt') {
          args.cell.classList.add('status-circle', 'status-green')
        } else if (args.data['status'] === 'teilbezahlt') {
          args.cell.classList.add('status-circle', 'status-yellow')
        } else if (args.data['status'] === 'überfällig') {
          args.cell.classList.add('status-circle', 'status-orange')
        } else if (args.data['status'] === 'angemahnt') {
          args.cell.classList.add('status-circle', 'status-red')
        } else if (args.data['status'] === 'storniert') {
          args.cell.classList.add('status-circle', 'status-black')
        } else {
          args.cell.classList.add('status-circle', 'status-white')
        }
      }
      if (args.column.field === 'payment_status') {
        if (args.data['payment_status'] === 'bezahlt') {
          args.cell.classList.add('status-circle', 'status-green')
        } else if (args.data['payment_status'] === 'teilbezahlt') {
          args.cell.classList.add('status-circle', 'status-yellow')
        } else if (args.data['payment_status'] === 'überfällig') {
          args.cell.classList.add('status-circle', 'status-orange')
        } else if (args.data['payment_status'] === 'angemahnt') {
          args.cell.classList.add('status-circle', 'status-red')
        } else if (args.data['payment_status'] === 'storniert') {
          args.cell.classList.add('status-circle', 'status-black')
        } else {
          args.cell.classList.add('status-circle', 'status-white')
        }
      }
    },
    clickToolbar: function (args) {
      if (args.item.id === 'addClass') {
        this.showModal = true
        this.modalEdit = false
      }
    },
    selectRow (row) {
      if (this.showModal === false) {
        clearSelection()
        axios.get('get_booking.php?id=' + row.data.id).then(response => {
          // this.selectedGrid = row.target.closest('.e-grid').ej2_instances[0]
          this.model = response.data
          this.showModal = true
          this.modalEdit = true
        })
      }
    },
    deselectRow () {
      clearSelection()
    },
    nextRecord () {
      var currentRow = this.$refs.grid.getSelectedRowIndexes()
      currentRow = currentRow[0]
      var currentRecord = this.$refs.grid.getSelectedRecords()
      currentRecord = currentRecord[0]
      var currentParticipant = currentRecord.id
      var currentBooking = currentRecord.payment_id
      var nextRow = currentRow + 1
      this.$refs.grid.selectRow(nextRow)
      var nextRecord = this.$refs.grid.getSelectedRecords()
      nextRecord = nextRecord[0]
      var nextParticipant = nextRecord.id
      var nextBooking = nextRecord.payment_id
      if (nextBooking === currentBooking && nextParticipant != currentParticipant) {
        axios.get('get_booking.php?id=' + nextParticipant).then(response => {
          this.model = response.data
        })
      } else {
        this.$refs.grid.selectRow(currentRow)
      }
    },
    previousRecord () {
      var currentRow = this.$refs.grid.getSelectedRowIndexes()
      currentRow = currentRow[0]
      var currentRecord = this.$refs.grid.getSelectedRecords()
      currentRecord = currentRecord[0]
      var currentParticipant = currentRecord.id
      var currentBooking = currentRecord.payment_id
      var previousRow = currentRow - 1
      this.$refs.grid.selectRow(previousRow)
      var previousRecord = this.$refs.grid.getSelectedRecords()
      previousRecord = previousRecord[0]
      var previousParticipant = previousRecord.id
      var previousBooking = previousRecord.payment_id
      if (previousBooking === currentBooking && previousParticipant != currentParticipant) {
        axios.get('get_booking.php?id=' + previousParticipant).then(response => {
          this.model = response.data
        })
      } else {
        this.$refs.grid.selectRow(currentRow)
      }
    },
    closeModal () {
      this.$refs.grid.refresh()
      this.showModal = false
      this.pillsActive = 0
      this.resetModel()
    },
    saveModal() {
      // event.target.classList.add('was-validated')
      if (this.model.participant_title !== '' && this.model.participant_first_name !== '' && this.model.participant_last_name !== '' && this.model.parent_title !== '' && this.model.parent_first_name !== '' && this.model.parent_last_name !== '' && this.model.participant_street !== '' && this.model.participant_zip !== '' && this.model.participant_city !== '' && this.model.participant_email !== '' && this.model.payment_target !== '') {
        var url = ''
        if (this.modalEdit === true) {
          url = 'edit_booking.php'
        } else {
          url = 'add_booking.php'
        }
        axios({
          method: 'post',
          url: url,
          data: this.model
        }).then(response => {
          if (response.data.success === false) {
            alert(response.data.error)
          } else {
            // event.target.classList.remove('was-validated')
            // this.$refs.grid.refresh()
            // this.showModal = false
            // this.pillsActive = 0
            // this.resetModel()
          }
        }).catch(error => {
          alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
        })
      }
    },
    changePayment () {
      // event.target.classList.add('was-validated')
      if (this.payment.sum !== '' && this.payment.method !== '') {
        var url = 'change_payment.php'
        axios({
          method: 'post',
          url: url,
          data: this.payment
        }).then(response => {
          if (response.data.success === false) {
            alert(response.data.error)
          } else {
            this.model.payment_date = response.data.payment_date
            this.model.payment_total_paid = response.data.payment_total_paid
            this.model.payment_total_due = response.data.payment_total_due
            this.model.status = response.data.status
            this.model.payment_participant_paid = response.data.payment_participant_paid
            this.model.payment_participant_due = response.data.payment_participant_due
            this.model.payment_status = response.data.payment_status
            this.model.payment_type = response.data.payment_type
            this.model.coupon_code = response.data.coupon_code
            this.model.coupon_value_total = response.data.coupon_value_total
            this.model.coupon_value_spent = response.data.coupon_value_spent
            this.model.payment_memo = response.data.payment_memo
            this.model.payment_log = response.data.payment_log
            // this.$refs.grid.refresh()
            this.showPayment = false
          }
        }).catch(error => {
          alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
        })
      }
    },
    changeCompletePayment () {
      // event.target.classList.add('was-validated')
      if (this.payment.sum !== '' && this.payment.complete_method !== '') {
        var url = 'change_complete_payment.php'
        axios({
          method: 'post',
          url: url,
          data: this.payment
        }).then(response => {
          if (response.data.success === false) {
            alert(response.data.error)
          } else {
            this.model.payment_date = response.data.payment_date
            this.model.parent_payment_date = response.data.payment_date
            this.model.payment_total_paid = response.data.payment_total_paid
            this.model.payment_total_due = response.data.payment_total_due
            this.model.status = response.data.status
            this.model.payment_participant_paid = response.data.payment_participant_paid
            this.model.payment_participant_due = response.data.payment_participant_due
            this.model.payment_status = response.data.payment_status
            this.model.payment_type = response.data.payment_type
            this.model.parent_payment_type = response.data.parent_payment_type
            this.model.parent_deposited_coupon_code = response.data.parent_deposited_coupon_code
            this.model.parent_coupon_code = response.data.coupon_code
            this.model.parent_coupon_value_total = response.data.coupon_value_total
            this.model.parent_coupon_value_spent = response.data.coupon_value_spent
            this.model.payment_memo = response.data.payment_memo
            this.model.payment_log = response.data.payment_log
            // this.$refs.grid.refresh()
            this.showCompletePayment = false
          }
        }).catch(error => {
          alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
        })
      }
    },
    sendReminder () {
      var url = 'send_reminder.php'
      axios({
        method: 'post',
        url: url,
        data: this.model
      }).then(response => {
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.model.status = response.data.status
          this.showReminder = false
        }
      }).catch(error => {
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    sendInvoice () {
      var url = 'send_invoice.php'
      axios({
        method: 'post',
        url: url,
        data: this.model
      }).then(response => {
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.showInvoice = false
        }
      }).catch(error => {
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    sendReceipt () {
      var url = 'send_receipt.php'
      axios({
        method: 'post',
        url: url,
        data: this.model
      }).then(response => {
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          //this.$refs.grid.refresh()
          this.showReceipt = false
        }
      }).catch(error => {
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    transferBooking () {
      this.$refs.transfer.validate()
      // event.target.classList.add('was-validated')
      if (this.transfer.newClass !== null) {
        var url = 'transfer_booking.php'
        axios({
          method: 'post',
          url: url,
          data: this.transfer
        }).then(response => {
          if (response.data.success === false) {
            alert(response.data.error)
          } else {
            this.$refs.grid.refresh()
            this.showTransfer = false
            this.pillsActive = 0
            this.showModal = false
            this.resetModel()
          }
        }).catch(error => {
          alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
        })
      }
    },
    getCancelType (value) {
      this.cancelType = value
    },
    cancelBooking () {
      var url = 'cancel_booking.php?type=' + this.cancelType
      axios({
        method: 'post',
        url: url,
        data: this.model
      }).then(response => {
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showCancel = false
          this.pillsActive = 0
          this.showModal = false
          this.resetModel()
        }
      }).catch(error => {
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    restoreBooking () {
      var url = 'restore_booking.php'
      axios({
        method: 'post',
        url: url,
        data: this.model
      }).then(response => {
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showRestore = false
          this.pillsActive = 0
          this.showModal = false
          this.resetModel()
        }
      }).catch(error => {
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    getTransferValue (id) {
      this.transfer.newClass = id
    },
    printParticipants () {
      window.open('https://fpk.sempre-diritto.de/api/print_participants.php?id=' + this.model.class, '_blank');
    },
    resetModel () {
      this.model.id = 'new'
      this.model.parent = 0
      this.model.created = ''
      this.model.terms_accepted = ''
      this.model.cancelled = ''
      this.model.class = ''
      this.model.class_title = ''
      this.model.slots = 1
      this.model.capacity = ''
      this.model.memo = ''
      this.model.status = 'offen'
      this.model.coupon = ''
      this.model.coupon_code = ''
      this.model.coupon_value_total = '0,00'
      this.model.coupon_value_spent = '0,00'
      this.model.payment_id = ''
      this.model.payment_date = ''
      this.model.payment_type = 'Überweisung'
      this.model.payment_target = '14 Tage'
      this.model.payment_total = '0,00'
      this.model.payment_total_paid = '0,00'
      this.model.payment_total_due = '0,00'
      this.model.payment_participant = '0,00'
      this.model.payment_participant_paid = '0,00'
      this.model.payment_participant_due = '0,00'
      this.model.payment_memo = ''
      this.model.payment_status = 'offen'
      this.model.credit_date = ''
      this.model.credit_value = '0,00'
      this.model.participant_title = [
        { text: 'Herr', value: 'Herr', selected: false },
        { text: 'Frau', value: 'Frau', selected: false }
      ]
      this.model.participant_first_name = ''
      this.model.participant_last_name = ''
      this.model.participant_full_name = ''
      this.model.participant_fullest_name = ''
      this.model.participant_company = ''
      this.model.participant_street = ''
      this.model.participant_street_additional = ''
      this.model.participant_zip = ''
      this.model.participant_city = ''
      this.model.participant_address = ''
      this.model.participant_phone = ''
      this.model.participant_email = ''
      this.model.participant_memo = ''
      this.model.parent_title = [
        { text: 'Herr', value: 'Herr', selected: false },
        { text: 'Frau', value: 'Frau', selected: false }
      ]
      this.model.parent_first_name = ''
      this.model.parent_last_name = ''
      this.model.parent_payment_date = ''
      this.model.parent_payment_type = ''
      this.model.parent_coupon_code = ''
      this.model.parent_coupon_value_total = ''
      this.model.parent_coupon_value_spent = ''
    },
    handleDateChange(value) {
      this.payment.date = value;
      this.$nextTick(this.validate);
    },
    validate() {
      if (this.model.date !== "") {
        this.isDateValid = true
      } else {
        this.isDateValid = false
      }
    }
  },
  provide: {
    grid: [Edit, Toolbar, Resize, Search, Sort, Group]
  }
}
window.format = function(value) {
  var x = value.count
  if (x > 1) {
    x = value.count + ' Einträge'
  } else {
    x = value.count + ' Eintrag'
  }
  var y = value.count
  if (y > 1) {
    y = value.count + ' Buchungen'
  } else {
    y = value.count + ' Buchung'
  }
  switch (value.field) {
    case 'payment_id':
      return 'Buchung' + ' <b>' + value.key + '</b> (' + value.count + ' Teilnehmer)'
    case 'class_title':
      return value.key + '</b> (' + y + ')'
    case 'test':
      return value.key + ' ' + x
    default:
      // return value.field + ': ' + value.key + ' ' + x
      return value.key + ' <span class="grey-text">(' + x + ')</span>'
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.e-grid .e-rowcell.date-column {
  color: #79b51c !important;
  font-weight: bold;
  font-size: 15px;
}

.e-grid .e-rowcell.title-column {
  font-weight: normal;
  font-size: 16px;
}

.e-grid .e-rowcell.capacity-column {
  font-weight: 500;
  font-size: 16px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}

.padding-label label {
  margin-bottom: 0px;
  margin-top: 14px;
}

.history p {
  margin-bottom: 2px;
}

</style>
